import {makeAutoObservable} from 'mobx'

export default class CateringModel {
    constructor() {
        makeAutoObservable(this)
    }

    cateringDescription = ''
    catering = []

}
