import {makeAutoObservable} from 'mobx'

export default class AccommodationModel {
    constructor() {
        makeAutoObservable(this)
    }

    description = ''
    room = []
    items = []

}
