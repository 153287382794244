import CountdownTimer from "@components/counter_down/CountdownTimer";
import StateView from "@components/StateView/StateView";
import AuthController from "@controller/auth/AuthController";
import { Button, Checkbox, Divider, Form, Input } from "antd";
import Cookies from "js-cookie";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiErrorCircle } from "react-icons/bi";
import PhoneInput from "react-phone-input-2";
import de from "react-phone-input-2/lang/de.json";

const PinInput = dynamic(() => import("react-pin-input"), { ssr: false });
const SocialLogin = dynamic(() => import("@layout/header/SocialLogin"), {
  ssr: false,
});

const LoginForms = ({
  mode,
  controller = new AuthController(),
  fromOfferPage,
}) => {
  const [rememberLogin, setRememberLogin] = useState(true);
  const { t, i18n } = useTranslation("common");
  const pinRef = useRef();

  const _title = (title = "", subtitle = "", className = "") => {
    return (
      <div className={className}>
        <h2 className="font-bold text-[22px] mb-2">{title}</h2>
        <p className={"text-center text-base"}>{subtitle}</p>
      </div>
    );
  };

  const handleRememberUser = (data) => {
    if (!rememberLogin) {
      return;
    }

    Cookies.set("rememberLogin", JSON.stringify(data));
    return window.localStorage.setItem("rememberLogin", JSON.stringify(data));
  };

  const _afterLogin = () => {};

  return (
    <>
      {mode === "register" ? (
        // Register
        <div className={"h-full justify-between flex flex-col"}>
          <div className={"flex flex-col"}>
            <div className={"flex justify-center mb-5"}>
              <img src={"/static/images/logo/mini.svg"} className={"w-10"} />
            </div>
            {fromOfferPage ? (
              <p className={"flex justify-center mb-5 text-lg text-center"}>
                {t("auth.from_offer_reg_title")}
              </p>
            ) : (
              <div className={"flex justify-center mb-5 text-lg text-center"}>
                {_title(
                  t("auth.welcome_login"),
                  t("auth.welcome_login_subtitle_register"),
                  "flex flex-col items-center"
                )}
              </div>
            )}
          </div>

          <div className={"flex- flex-col"}>
            <StateView
              state={controller.loginStateView}
              bodyClass={"flex flex-col h-full"}
            >
              <div className={"flex flex-col  h-full justify-between"}>
                <div>
                  <div className={"flex"}>
                    <Form
                      onFinish={(data) => {
                        controller.register(data, () => {});
                      }}
                      className={"w-100 flex flex-col form-base gap-y-3"}
                      layout={"inline"}
                    >
                      {/* Name */}
                      <Form.Item
                        name="name"
                        rules={[
                          {
                            required: true,
                            message: t("validation.required_message", {
                              name: t("login.name"),
                            }),
                          },
                        ]}
                        help={
                          controller.errors.hasOwnProperty("name")
                            ? controller.errors.name.join(",")
                            : null
                        }
                        className="!rounded-full text-base padding-left !outline !outline-gray-200 !ouline-[0.5px] !h-[44px] in"
                      >
                        <Input
                          placeholder={t("login.name")}
                          className="!w-full !h-[44px] !rounded-full"
                        />
                      </Form.Item>

                      {/* Email */}
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            type: "email",
                            message: t("validation.invalid_email_format"),
                          },
                          {
                            required: true,
                            message: t("validation.required_message", {
                              name: t("login.email_address"),
                            }),
                          },
                        ]}
                        help={
                          controller.errors.hasOwnProperty("email")
                            ? controller.errors.email.join(",")
                            : null
                        }
                        className="padding-left text-base !rounded-3xl focus:border-orange !outline !ouline-[0.5px] !outline-gray-200 !h-[44px] in"
                      >
                        <Input
                          placeholder={t("login.email_address")}
                          type="email"
                          className="!w-full !h-[44px] !rounded-full"
                        />
                      </Form.Item>

                      {/* Password */}
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: t("validation.required_message", {
                              name: t("login.pass"),
                            }),
                          },
                        ]}
                        help={
                          controller.errors.hasOwnProperty("password")
                            ? controller.errors.password.join(",")
                            : null
                        }
                        className="rounded-full text-base !outline !outline-gray-200 !ouline-[0.5px] !h-[44px] in pass-input !mt-1 !bg-white [&_.ant-input-affix-wrapper]:!pl-0 [&_.ant-input-affix-wrapper]:!h-[48px] [&_.ant-input-affix-wrapper]:!pb-0 [&_.ant-input]:!pl-5"
                      >
                        <Input.Password
                          placeholder={t("login.pass")}
                          type={"password"}
                          className="!w-full !h-[44px] !rounded-full"
                        />
                      </Form.Item>

                      {/* Password Confirmation */}
                      <Form.Item
                        name="passwordConfirmation"
                        rules={[
                          {
                            required: true,
                            message: t("validation.required_message", {
                              name: t("login.pass"),
                            }),
                          },
                        ]}
                        help={
                          controller.errors.hasOwnProperty("password")
                            ? controller.errors.password.join(",")
                            : null
                        }
                        className="rounded-full text-base !outline !outline-gray-200 !ouline-[0.5px] !h-[44px] in pass-input !mt-1 !bg-white [&_.ant-input-affix-wrapper]:!pl-0 [&_.ant-input-affix-wrapper]:!h-[48px] [&_.ant-input-affix-wrapper]:!pb-0 [&_.ant-input]:!pl-5"
                      >
                        <Input.Password
                          placeholder={t("login.passRepeat")}
                          type={"password"}
                          className="!w-full !h-[44px] !rounded-full"
                        />
                      </Form.Item>

                      {/* Checkbox */}
                      <Form.Item name="ag_email">
                        <Checkbox.Group
                          options={[t("auth.rule_register")]}
                        ></Checkbox.Group>
                      </Form.Item>
                      <p className="text-gray-50 px-0 ml-[25px] text-[14px] leading-5 md:-mt-7">
                        {t("auth.register_text")}
                      </p>

                      <div className={"pt-5"}>
                        <Button
                          htmlType={"submit"}
                          loading={controller.loadingRegister}
                          block
                          className={
                            "btn-orange hover:bg-orange hover:border-orange flex flex-row space-x-3 justify-center"
                          }
                        >
                          <div className={"flex font-bold text-lg"}>
                            {t("login.btn_signup")}
                          </div>
                        </Button>
                      </div>
                      <div
                        className={
                          "flex flex-row text-center flex-wrap gap-1 mb-5 text-[14px] justify-center items-center px-1 md:px-0"
                        }
                      >
                        <span className={"text-center text-[14px]"}>
                          {t("auth.register_terms1")}
                        </span>
                        <Link
                          href={
                            "https://www.moverii.net/content/protection/terms"
                          }
                        >
                          <a
                            href={
                              "https://www.moverii.net/content/protection/terms"
                            }
                            target="_blank"
                            className="text-center hover:underline !text-green font-bold text-[14px]"
                          >
                            {t("auth.register_terms2")}
                          </a>
                        </Link>
                        <span className={"text-center text-[14px]"}>
                          {t("auth.register_terms3")}
                        </span>
                        <Link
                          href={
                            "https://www.moverii.net/content/protection/data-protection"
                          }
                        >
                          <a
                            href={
                              "https://www.moverii.net/content/protection/data-protection"
                            }
                            target="_blank"
                            className="hover:underline !text-green text-center font-bold text-[14px]"
                          >
                            {t("auth.register_terms4")}
                          </a>
                        </Link>
                        {i18n.language === "de" && (
                          <p className="text-[14px]">
                            {t("auth.register_german_terms_5")}
                          </p>
                        )}
                        <span className="-ml-1">.</span>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </StateView>
          </div>
          <div className={"flex-col flex"}>
            <div className="flex flex-col justify-center align-center cursor-pointer space-y-2">
              {!fromOfferPage && (
                <div
                  className="mr-0 mt-2 w-full hover:border-custom-orange border relative h-12 flex flex-row   rounded-full p-2 border-black hover:bg-gray-100"
                  onClick={() => {
                    controller.viewMod = "phone";
                  }}
                >
                  <div className={"absolute left-6"}>
                    <img src="/static/icons/mobilelogin.svg" alt="" />
                  </div>
                  <div
                    className={
                      "flex w-full text-center self-center justify-center text-base"
                    }
                  >
                    {controller.viewMod === "register"
                      ? t("auth.register_with_phone")
                      : t("auth.login_with_mobile")}
                  </div>
                </div>
              )}
              <SocialLogin
                controller={controller}
                closeEvent={() => handleclose(false)}
                fromOfferPage={fromOfferPage}
              />
            </div>
          </div>

          {/* Bottom part */}
          <div className="text-gray-50">
            <Divider />
          </div>

          <div
            className={"space-x-1 cursor-pointer flex flex-row self-center"}
            onClick={() => {
              controller.changeTab("email");
            }}
          >
            <span className="text-base">{t("auth.already_have_account")}</span>
            <span className={"text-green text-base"}>
              {t("auth.can_login2")}
            </span>
          </div>
        </div>
      ) : mode === "email" ? (
        // login
        <div className={"h-full justify-between flex flex-col"}>
          <div className={"flex flex-col"}>
            <div className={"flex justify-center mb-5"}>
              <img src={"/static/images/logo/mini.svg"} className={"w-10"} />
            </div>
            {fromOfferPage ? (
              <p className={"mb-5 text-lg text-center font-normal"}>
                {t("auth.from_offer_login_title_1")}
                <span
                  className="text-green underline cursor-pointer hover:no-underline"
                  onClick={() => {
                    controller.changeTab("register");
                  }}
                >
                  {t("auth.from_offer_login_title_2")}
                </span>
                !
              </p>
            ) : (
              <div className={"flex justify-center mb-5 text-lg text-center"}>
                {_title(
                  t("auth.welcome_login"),
                  t("auth.welcome_login_subtitle_login"),
                  "flex flex-col items-center"
                )}
              </div>
            )}
          </div>
          {/* content */}
          <div className={"flex- flex-col"}>
            <Form
              onFinish={(data) => {
                controller.emailLogin(data);
                handleRememberUser(data);
              }}
              className={"w-100 flex flex-col form-base gap-y-3"}
              layout={"inline"}
            >
              {/* email */}
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: t("validation.invalid_email_format"),
                  },
                  {
                    required: true,
                    message: t("validation.required_message", {
                      name: t("login.email"),
                    }),
                  },
                ]}
                className="!rounded-full text-base padding-left !outline !outline-gray-200 !ouline-[0.5px] !h-[44px] in"
              >
                <Input
                  placeholder={t("login.email")}
                  type="email"
                  className="!w-full !h-[44px] !rounded-full"
                />
              </Form.Item>

              {/* Password */}
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("validation.required_message", {
                      name: t("login.password"),
                    }),
                  },
                ]}
                className="rounded-full text-base !outline !outline-gray-200 !ouline-[0.5px] !h-[44px] in pass-input !mt-1 !bg-white [&_.ant-input-affix-wrapper]:!pl-0 [&_.ant-input-affix-wrapper]:!h-[48px] [&_.ant-input-affix-wrapper]:!pb-0 [&_.ant-input]:!pl-5"
              >
                <Input.Password
                  placeholder={t("login.password")}
                  type={"password"}
                  className="!w-full !h-[44px] !rounded-full !pl-6"
                />
              </Form.Item>

              <div className="flex items-center justify-between md:px-1">
                {/* Checkbox - Remember me */}
                <div>
                  <Checkbox
                    defaultChecked={true}
                    onChange={(e) => setRememberLogin(e.target.checked)}
                  >
                    {t("auth.checkbox_remember_me")}
                  </Checkbox>
                </div>

                {/* Forgot password */}
                <div className={"flex self-end"}>
                  <button
                    className={"hover:text-green"}
                    onClick={() => (controller.viewMod = "forget")}
                  >
                    {t("login.login_forget")}
                  </button>
                </div>
              </div>

              {/* Error messeage */}
              {controller.loginFailed && (
                <div className="px-4 flex rounded-2xl py-3 z-0 relative overflow-hidden gap-3 items-center mt-2 border-2 border-red">
                  {/* <span className="absolute w-full h-full top-0 left-0 right-0 opacity-30" /> */}
                  <span className="z-10">
                    <BiErrorCircle className="text-[50px] text-red bg-white rounded-full p-1" />
                  </span>

                  <span className="flex flex-col text-sm font-normal text-[#212121] z-10">
                    <p>{t("login.login_error_1")}</p>

                    <p>
                      {t("login.login_error_2")}{" "}
                      <a
                        href="https://provider.moverii.de/en?action=login"
                        target="_blank"
                        className="!underline !text-inherit cursor-pointer"
                      >
                        {t("login.login_error_link_3")}
                      </a>
                      .
                    </p>
                  </span>
                </div>
              )}

              <div className={"my-5"}>
                <Button
                  loading={controller.loadingOtp}
                  block
                  htmlType={"submit"}
                  className={
                    "btn-orange hover:bg-orange hover:border-orange !flex !items-center justify-center rounded-full"
                  }
                >
                  <p className={"flex text-lg font-bold"}>
                    {t("login.btn_login")}
                  </p>
                </Button>
              </div>
            </Form>
          </div>

          <div className={`flex-col flex ${fromOfferPage ? "mb-3" : ""}`}>
            <div className="flex flex-col justify-center align-center cursor-pointer space-y-2">
              <Divider>
                <span
                  className={"text-gray-50 font-normal text-base !uppercase"}
                >
                  {" "}
                  {t("login.login_with")}{" "}
                </span>
              </Divider>
              {!fromOfferPage && (
                <div
                  className="mr-0 mt-2 w-full hover:border-custom-orange border relative h-12 flex flex-row   rounded-full p-2 border-black hover:bg-gray-100"
                  onClick={() => {
                    controller.viewMod = "phone";
                  }}
                >
                  <div className={"absolute left-6"}>
                    <img src="/static/icons/mobilelogin.svg" alt="" />
                  </div>
                  <div
                    className={
                      "flex w-full text-center self-center justify-center text-base"
                    }
                  >
                    {controller.viewMod === "register"
                      ? t("auth.register_with_phone")
                      : t("auth.login_with_mobile")}
                  </div>
                </div>
              )}
              <SocialLogin
                controller={controller}
                closeEvent={() => handleclose(false)}
                fromOfferPage={fromOfferPage}
              />
            </div>
          </div>

          {/* Bottom part */}
          {!fromOfferPage && (
            <>
              <div className="text-gray-50">
                <Divider />
              </div>

              <div
                className={
                  "space-x-1 cursor-pointer flex flex-row self-center text-base"
                }
                onClick={() => {
                  controller.changeTab("register");
                }}
              >
                <span className="text-base">{t("auth.new_here")}</span>
                <span className={"text-green text-base"}>
                  {t("auth.create_an_account")}
                </span>
              </div>
            </>
          )}
        </div>
      ) : mode === "otp" ? (
        // Mobile code verification
        <StateView
          state={controller.loginStateView}
          bodyClass={"flex flex-col  h-full "}
        >
          <div className={"flex flex-col  h-full justify-between"}>
            <div className={"flex justify-center mb-5"}>
              <img src={"/static/images/logo/mini.svg"} className={"w-10"} />
            </div>
            <div
              className={"flex justify-center items-center text-center mb-5"}
            >
              {_title(
                t("login.title_login"),
                t("login.sub_title_otp", {
                  phone: controller.phone.replace(
                    /\D*(\d{2})\D*(\d{3})\D*(\d{3})\D*(\d{3})D*(\d{3})/,
                    "$1 $2 $3 $4 $5"
                  ),
                })
              )}
            </div>
            <div className={"flex flex-col w-full items-center"}>
              <div className={"flex flex-row self-center"}>
                <PinInput
                  length={5}
                  initialValue=""
                  secret
                  onChange={(value, index) => {}}
                  ref={pinRef}
                  type="numeric"
                  inputMode="number"
                  style={{ padding: "5px" }}
                  inputStyle={{
                    borderColor: "#E5EBF8",
                    marginRight: "10px",
                    borderRadius: "15px",
                    backgroundColor: "#F5F8FE",
                  }}
                  inputFocusStyle={{
                    borderColor: "#E5EBF8",
                    borderRadius: "15px",
                    backgroundColor: "#fff",
                  }}
                  onComplete={(value, index) => {
                    controller.otp = value;
                    controller.loginWithOtp(_afterLogin, () => {
                      pinRef.current.retry();
                    });
                  }}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
                {controller.canReOtp && (
                  <div className={"flex flex-row space-x-2 self-center"}>
                    <div className={"flex"}>
                      <img src={"/static/icons/timer.svg"} />
                    </div>
                    <div className={"flex "}>
                      <CountdownTimer
                        timeLeft={90000}
                        completeCallback={() => {
                          controller.enabledResend();
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div
                className={
                  "flex flex-row justify-between mt-0 mb-8 sm:w-[410px]"
                }
              >
                <div className={"flex "}>
                  <Button
                    onClick={() => {
                      controller.backToNumber();
                    }}
                    type={"link"}
                    block
                  >
                    <p
                      className={"text-center !text-[#12a3ba] hover:underline"}
                    >
                      {t("login.change_number")}
                    </p>
                  </Button>
                </div>
                <div className={"flex flex-row "}>
                  <Button
                    disabled={controller.canReOtp}
                    loading={controller.resendLoading}
                    type={"primary"}
                    onClick={() => {
                      controller.resendOtp();
                    }}
                    htmlType={"button"}
                    className={
                      "border-0 hover:bg-white !bg-white  disabled:bg-white shadow-none flex  flex-row space-x-2"
                    }
                  >
                    <p className={"flex !text-[#12a3ba] hover:underline"}>
                      {t("login.resend")}
                    </p>
                  </Button>
                </div>
              </div>
            </div>
            <div className={"flex flex-col md:mt-0 mt-5"}>
              <Button
                loading={controller.loadingOtp}
                className={
                  "btn-orange hover:bg-orange hover:border-orange flex items-center space-x-3 justify-center"
                }
                onClick={() =>
                  controller.loginWithOtp(_afterLogin, () => {
                    pinRef.current.retry();
                  })
                }
              >
                <div className={"flex text-base font-semibold"}>
                  {t("login.btn_login")}
                </div>
              </Button>
            </div>
            <div className="text-gray-50">
              <Divider className={""}>
                <span className={"text-gray-50 font-normal text-xs"}>
                  {" "}
                  {t("login.login_with")}{" "}
                </span>
              </Divider>
              <SocialLogin
                controller={controller}
                closeEvent={() => handleclose(false)}
              />
            </div>
          </div>
        </StateView>
      ) : mode === "forget" ? (
        // Forget password
        <StateView
          state={controller.loginStateView}
          bodyClass={"flex flex-col  h-full"}
        >
          {controller.passwordResetSuccess == undefined ||
          controller.passwordResetSuccess == false ? (
            <div className={"flex flex-col  h-full justify-between"}>
              <div className={"flex flex-col"}>
                <div className={"flex justify-center mb-5"}>
                  <img
                    src={"/static/images/logo/mini.svg"}
                    className={"w-10"}
                  />
                </div>
                <div className={"flex justify-center mb-5 text-center"}>
                  {_title(
                    t("auth.welcome_login"),
                    t("auth.forgot_pass_modal_text"),
                    "flex flex-col items-center "
                  )}
                </div>
              </div>

              <div>
                <div className={"flex"}>
                  <Form
                    onFinish={(data) => {
                      controller.resetPassword(data);
                    }}
                    className={"w-100 flex flex-col  form-base "}
                    layout={"inline"}
                  >
                    <Form.Item
                      name="forget"
                      rules={[
                        {
                          required: true,
                          message: t("validation.forgot_pass_error"),
                        },
                      ]}
                      help={
                        controller.errors.hasOwnProperty("forget")
                          ? controller.errors.name.join(",")
                          : null
                      }
                      className="text-base !rounded-3xl outline !outline-gray-200 !ouline-[0.5px] !h-[44px] focus:!border-none"
                    >
                      <Input
                        placeholder={t("auth.forget_placeholder")}
                        className="!w-full !h-[44px] !rounded-full"
                      />
                    </Form.Item>

                    <div className={"mt-5"}>
                      <Button
                        htmlType="submit"
                        loading={controller.loadingReset}
                        block
                        className="btn-orange hover:bg-orange hover:border-orange flex flex-row space-x-3 justify-center"
                      >
                        <div className={"flex font-bold text-lg"}>
                          {t("login.btn_reset")}
                        </div>
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>

              <div className={"flex flex-col self-center"}>
                <Divider />
                <Button
                  type={"link"}
                  className={"flex flex-row  space-x-2"}
                  onClick={() => {
                    controller.changeTab("email");
                  }}
                >
                  <span className={"text-black text-base"}>
                    {t("auth.can_login1")}
                  </span>
                  <span className={"text-green text-base"}>
                    {t("auth.can_login2")}
                  </span>
                </Button>
              </div>
            </div>
          ) : (
            // Success Message
            <div className="w-full flex flex-col justify-center items-center my-10 gap-10">
              <img src={"/static/images/confirm.svg"} className="w-[150px]" />
              <p className="text-base text-center font-medium">
                {t("auth.forgot_pw_success_message")}
              </p>
            </div>
          )}
        </StateView>
      ) : mode === "phone" ? (
        // Login by phone - Code
        <div className={"h-full justify-between flex flex-col"}>
          <div className={"flex flex-col"}>
            <div className={"flex justify-center mb-5"}>
              <img src={"/static/images/logo/mini.svg"} className={"w-10"} />
            </div>
            <div className={"flex justify-center mb-5 text-lg text-center"}>
              {_title(
                t("auth.welcome_login"),
                t(""),
                "flex flex-col items-center "
              )}
            </div>
          </div>

          <div className={"flex- flex-col"}>
            <div className={"flex flex-col space-y-6"}>
              <p className={"text-center text-sm"}>
                {/* Mobile title */}
                {controller.viewTab === "register"
                  ? t("auth.welcome_login_subtitle_register")
                  : t("auth.welcome_login_subtitle_login")}
              </p>
              <PhoneInput
                country={"de"}
                inputProps={{
                  required: true,
                  autoFocus: true,
                }}
                countryCodeEditable={false}
                // value={controller.phone}
                enableAreaCodes={["de", "fr"]}
                localization={de}
                regions={"europe"}
                onChange={(phone) => (controller.phone = phone)}
              />

              {/* name & email added only for registeration */}
              {controller.viewTab === "register" && (
                <Form
                  onFinish={(data) => {
                    controller.mobileReg(data);
                  }}
                  className={"w-100 flex flex-col form-base gap-y-2 !mt-2"}
                  layout={"inline"}
                >
                  <Form.Item
                    name="name"
                    className={"w-full"}
                    rules={[
                      {
                        required: true,
                        message: `Please write your name!`,
                      },
                    ]}
                  >
                    <Input
                      className="w-full"
                      style={{ border: "1px solid #ececf6" }}
                      placeholder={t(
                        "landing.euro_contact_us_input_first_name"
                      )}
                    />
                  </Form.Item>

                  <Form.Item
                    name="email"
                    className={"w-full"}
                    rules={[
                      {
                        required: true,
                        message: `Please write your Email!`,
                        type: "email",
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      className="w-full"
                      style={{ border: "1px solid #ececf6" }}
                      placeholder={t("login.email")}
                    />
                  </Form.Item>

                  <Button
                    loading={controller.loadingOtp}
                    htmlType="submit"
                    className={
                      "btn-orange hover:bg-orange hover:border-orange flex items-center space-x-3 justify-center"
                    }
                  >
                    <div className={"flex text-base font-semibold"}>
                      {t("login.get_otp_login")}
                    </div>
                  </Button>
                </Form>
              )}
            </div>
          </div>
          <div className={"flex-col flex"}>
            <div className="text-gray-50">
              <Divider>
                <span className={"text-gray-50 font-normal text-xs"}>
                  {" "}
                  {t("login.login_with")}{" "}
                </span>
              </Divider>
            </div>

            <div className="flex flex-col justify-center align-center cursor-pointer space-y-2">
              <div
                className="mr-0 mt-2 w-full border relative h-12 flex flex-row   rounded-full p-2 border-black hover:border-black hover:bg-gray-100"
                onClick={() => {
                  controller.viewMod = "email";
                }}
              >
                <div className={"absolute left-5 "}>
                  <img src="/static/icons/envelope.svg" alt="" />
                </div>
                <div
                  className={
                    "flex w-full  text-center self-center justify-center"
                  }
                >
                  {t("auth.login_with_email")}
                </div>
              </div>

              <SocialLogin
                controller={controller}
                closeEvent={() => handleclose(false)}
              />
            </div>
          </div>

          {/* Bottom part */}
          <div className="text-gray-50">
            <Divider />
          </div>

          <div
            className={
              "space-x-1 cursor-pointer flex flex-row self-center text-base"
            }
            onClick={() => {
              controller.changeTab("register");
            }}
          >
            <span className="text-base">{t("auth.new_here")}</span>
            <span className={"text-green text-base"}>
              {t("auth.create_an_account")}
            </span>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default LoginForms;
