import { useTranslation } from "next-i18next";
import Head from "next/head";
import Script from "next/script";

const EmailForm = () => {
  const { t } = useTranslation("common");

  return (
    <>
      <Head>
        <link
          href="//cdn-images.mailchimp.com/embedcode/classic-061523.css"
          rel="stylesheet"
          type="text/css"
        />
        <style type="text/css">
          {`#mc_embed_signup{background:#fff; false;clear:left; font:14px Helvetica,Arial,sans-serif; width: 600px;}`}
        </style>
      </Head>

      <div className="!w-full !m-0" id="mc_embed_shell">
        <div id="mc_embed_signup ">
          <form
            action="https://moverii.us12.list-manage.com/subscribe/post?u=9cc1bdce92f61fb452175cf6d&amp;id=1bc3a8634b&amp;f_id=003e46e0f0"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate !w-full"
            target="_blank"
          >
            <div id="mc_embed_signup_scroll !w-full">
              <div className="mc-field-group !w-full ">
                <input
                  type="text"
                  name="FNAME"
                  className="required text !w-full border-2 !mb-4 !h-[50px] rounded-3xl px-5 text-base border-[#ececf6]"
                  id="mce-FNAME"
                  required={true}
                  placeholder={t("newsletter_first_name")}
                />
              </div>
              <div className="mc-field-group">
                <input
                  type="email"
                  name="EMAIL"
                  className="required email border-2 !w-full !mb-4 !h-[50px] rounded-3xl px-5 text-base border-[#ececf6]"
                  id="mce-EMAIL"
                  required={true}
                  placeholder={t("newsletter_email")}
                />
              </div>
              <div id="mce-responses" className="clear">
                <div
                  className="response"
                  id="mce-error-response"
                  style={{ display: "none" }}
                ></div>
                <div
                  className="response"
                  id="mce-success-response"
                  style={{ display: "none" }}
                ></div>
              </div>
              <div
                aria-hidden="true"
                style={{ position: "absolute", left: "-5000px" }}
              >
                <input
                  type="text"
                  name="b_9cc1bdce92f61fb452175cf6d_1bc3a8634b"
                  tabIndex="-1"
                  value=""
                />
              </div>
              <div className="clear !w-full">
                <input
                  type="submit"
                  name="subscribe"
                  aria-label={t("newsletter_button_register")}
                  id="mc-embedded-subscribe"
                  className="button !w-full !m-0 !p-0 !mb-4 !h-[50px] rounded-3xl text-base font-bold bg-orange text-white cursor-pointer"
                  value={t("newsletter_button_register")}
                />
              </div>
            </div>
          </form>
        </div>

        <Script
          src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
          strategy="afterInteractive"
        />
        <script
          type="text/javascript"
          strategy="afterInteractive"
          async
          dangerouslySetInnerHTML={{
            __html: `(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[1]='FNAME';ftypes[1]='text';fnames[0]='EMAIL';ftypes[0]='email';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';fnames[6]='MMERGE6';ftypes[6]='text';fnames[7]='MMERGE7';ftypes[7]='text';/*
                    * Translated default messages for the $ validation plugin.
                    * Locale: DE
                    */
                    $.extend($.validator.messages, {
                      required: "Dieses Feld ist ein Pflichtfeld.",
                      maxlength: $.validator.format("Geben Sie bitte maximal {0} Zeichen ein."),
                      minlength: $.validator.format("Geben Sie bitte mindestens {0} Zeichen ein."),
                      rangelength: $.validator.format("Geben Sie bitte mindestens {0} und maximal {1} Zeichen ein."),
                      email: "Geben Sie bitte eine gültige E-Mail Adresse ein.",
                      url: "Geben Sie bitte eine gültige URL ein.",
                      date: "Bitte geben Sie ein gültiges Datum ein.",
                      number: "Geben Sie bitte eine Nummer ein.",
                      digits: "Geben Sie bitte nur Ziffern ein.",
                      equalTo: "Bitte denselben Wert wiederholen.",
                      range: $.validator.format("Geben Sie bitten einen Wert zwischen {0} und {1}."),
                      max: $.validator.format("Geben Sie bitte einen Wert kleiner oder gleich {0} ein."),
                      min: $.validator.format("Geben Sie bitte einen Wert größer oder gleich {0} ein."),
                      creditcard: "Geben Sie bitte ein gültige Kreditkarten-Nummer ein."
                      });}(jQuery));var $mcj = jQuery.noConflict(true);`,
          }}
        ></script>
      </div>
    </>
  );
};

export default EmailForm;
