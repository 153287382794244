import { inject, observer } from "mobx-react";
import { createContext, useContext, useEffect, useState } from "react";
const FavoriteContext = createContext();

export const FavoriteProvider = inject("CoreStore")(
  observer(({ CoreStore, children }) => {
    const [favoriteOffers, setFavoriteOffers] = useState({
      ids: [],
      expire_at: null,
    });

    useEffect(() => {
      const storedFavorites = JSON.parse(
        localStorage.getItem("favoriteOffers")
      );
      if (storedFavorites) {
        setFavoriteOffers(storedFavorites);
      }
    }, []);

    useEffect(() => {
      if (
        CoreStore?.isAuth == "1" &&
        favoriteOffers.expire_at !== null &&
        favoriteOffers.ids.length > 0
      ) {
        localStorage.setItem("favoriteOffers", JSON.stringify(favoriteOffers));
      } else {
        localStorage.removeItem("favoriteOffers");
      }
    }, [favoriteOffers]);

    return (
      <FavoriteContext.Provider value={{ favoriteOffers, setFavoriteOffers }}>
        {children}
      </FavoriteContext.Provider>
    );
  })
);
export const useFavorite = () => useContext(FavoriteContext);
