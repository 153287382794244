import AccomodationModel from "@model/AccomodationModel";
import CateringModel from "@model/CateringModel";
import ProviderModel from "@model/ProviderModel";
import TransportationModel from "@model/TransportationModel";
import { makeAutoObservable, observable } from "mobx";

export default class OfferModel {
  constructor() {
    makeAutoObservable(this);
  }

  id = "";
  basePrice = 0;
  immediate = 0;
  expired = false;
  title = "";
  destination = "";
  provider_overview = "";
  target_group_description = "";
  video = "";
  terms_condition = "";
  Offer_type = "";
  teaserGeneral = "";
  image = observable([]);
  level = [];
  language = [];
  category = [];
  prices = [];
  benefits = [];
  country = [];
  review = [];
  duration = [];
  arrival_disable = [];
  rate = {};
  rooms = [];
  age = "";
  provider = new ProviderModel();
  accommodation = new AccomodationModel();
  catering = new CateringModel();
  transportation = new TransportationModel();
  sportDesription = "";
  accomodationSurroundings = "";
  provider_description = "";
  usps = [];
  time_plane = [];
  team = [];
  wished = "";
  included_features = "";

  overview = {};
  description = "";
  data = [];
  loading = false;

  faqs = [];
  map = [];

  setVals = (data) => {
    try {
      Object.keys(data).map((item, key) => {
        if (this[item] !== undefined) {
          this[item] = data[item];
        } else {
          console.log(item);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
}
