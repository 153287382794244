import classNames from "classnames";
import { useTranslation } from "react-i18next";
import style from "../../../styles/main.module.css";
import { Image } from "antd";

export default function Experience() {
  const { t } = useTranslation("common");

  return (
    <div className={"container mx-auto mt-[50px] lg:mt-[100px] px-4 md:px-0"}>
      <div
        className={classNames(
          "flex justify-between flex-col-reverse lg:flex-row mx-5 gap-10 lg:gap-0 lg:items-center"
        )}
      >
        <div
          className={classNames(
            style.video_exp_box,
            "hidden md:flex w-full lg:mr-10 md:max-h-[20rem] max-h-[50rem] justify-center rounded-2xl my-auto mx-auto lg:mx-0"
          )}
        >
          <div
            className={classNames(style.play_exp_box, "flex justify-center")}
          >
            <div className={style.play_icon}>
              <Image
                className={style.ds1}
                src="/main/ds1.svg"
                alt="play"
                loading="lazy"
                preview={false}
                fallback={"/static/images/placeholder/provider.jpg"}
              />
              <Image
                src="/static/images/ds2.svg"
                className={classNames(style.ds2)}
                alt="play"
                loading="lazy"
                preview={false}
                fallback={"/static/images/placeholder/provider.jpg"}
              />
            </div>
          </div>
          <Image
            preview={false}
            src="/static/images/home-categories/video-element.webp"
            alt="moverii.de"
            loading="lazy"
            className={
              "max-h-[20rem] md:h-[285px] h-[11rem] rounded-2xl w-full object-cover flex self-center"
            }
            fallback={"/static/images/placeholder/provider.jpg"}
          />
        </div>

        <div
          className={classNames(
            style.experience_detail,
            "lg:ml-8 px-2 sm:px-0 -mx-4 sm:mx-0"
          )}
        >
          <div className={"flex flex-row my-auto py-[24px] md:-ml-5"}>
            <h3 className={"!font-black catamaran inline-block lg:-ml-2"}>
              <span
                className={
                  "inline-block text-[24px] md:text-[28px] !font-black"
                }
              >
                {t("frontpage.section_experience_title")}
              </span>
              <span className={"inline-block align-middle -mt-[15px] px-2"}>
                <img
                  src="/static/images/logo.svg"
                  className={"w-[95px]"}
                  alt="moverii.de"
                  loading="lazy"
                />
              </span>
              <span
                className={
                  "inline-block text-[24px] md:text-[28px] !font-black"
                }
              >
                {t("frontpage.section_experience_title2")}
              </span>
            </h3>
          </div>

          <div className="flex flex-col space-y-3 gap-y-2 md:-ml-5">
            {[1, 2, 3, 4, 5].map((item) => (
              <div
                key={"experience-moverii-item-" + item}
                className="flex flex-row items-center gap-2"
              >
                <div className="w-[25px] h-[25px] flex">
                  <Image
                    src="/icon/offer/checkMark.svg"
                    width={25}
                    height={25}
                    loading="lazy"
                    preview={false}
                    alt="checkMark"
                  />
                </div>
                <p className="text-lg">
                  ... {t("frontpage.home_experience_item_" + item)}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
