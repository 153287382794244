import { Skeleton, Empty, Button } from "antd";
import PopularSlider from "../../../components/helper/popularSlider";
import { useTranslation } from "react-i18next";

export default function offersList(props) {
  const { t } = useTranslation("common");

  return (
    <>
      {props?.data?.data?.length > 0 ? (
        <div
          id="offer-index"
          className={`mx-auto container mt-[50px] lg:mt-[100px] sm:pb-10 px-4 md:px-0`}
        >
          <PopularSlider
            data={props?.data?.data}
            arrowClass="new-arrow-offer"
          />
        </div>
      ) : (
        <section className="mt-[50px] md:mt-[100px] container w-full flex flex-col items-center sm:pb-10 px-4 md:px-0 min-h-[607px]">
          <h2 className={"font-bold text-[28px] catamaran"}>
            {t("frontpage.title_offers")}
          </h2>
          {props?.hasFailed ? (
            <div className="w-full h-[500px] flex flex-col items-center justify-center gap-3">
              <Empty />
              <Button
                className="!bg-orange !text-white !border-none text-base font-bold px-6 md:px-12 w-full md:w-fit rounded-3xl h-[44px]"
                onClick={() => props.getData()}
              >
                Try again!
              </Button>
            </div>
          ) : (
            <div className="w-full flex items-center overflow-hidden gap-5 px-4 md:px-0 mt-5">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => (
                <Skeleton.Button
                  key={"offer-placeholder-skeleton-" + i}
                  active
                  className="!h-[500px] !w-[250px] md:!w-[260px] !rounded-3xl"
                />
              ))}
            </div>
          )}
        </section>
      )}
    </>
  );
}
