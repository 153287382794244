import request from "@libs/request/request";
import BannerTop from "@components/frontpage/banner_top";
import HomeSectioncomminity from "@components/frontpage/main_section_comminity";
import Discover from "@components/frontpage/category";
import Destination from "@components/frontpage/destination";
import Experience from "@components/frontpage/exprience";
import OffersList from "@components/frontpage/offersList";
import Testimonial from "@components/frontpage/traveler/testimonial";
import Traveler from "@components/frontpage/blog";
import { inject, observer } from "mobx-react";
import { i18n, useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { LogoJsonLd, NextSeo } from "next-seo";
import { useEffect, useState } from "react";
import BaseLayout from "../src/ui/layouts/base-layout";

const IndexPage = inject("CoreStore")(
  observer(() => {
    const [offerData, setOfferData] = useState([]);
    const [hasFailed, setHasFailed] = useState(false);
    const { t } = useTranslation("common");

    const getData = async () => {
      try {
        setHasFailed(false);
        const res = await request.get("site/index");
        const allData = res?.data?.data;

        if (allData?.length) {
          const offerComponent = allData?.find(
            (comp) => comp?.component_name === "offers"
          );
          if (offerComponent) setOfferData(offerComponent);
        }
      } catch (err) {
        setHasFailed(true);
        console.log(err.response?.data || err.message);
      }
    };

    useEffect(() => {
      getData();
    }, []);

    return (
      <div>
        <NextSeo
          title={t("tag.main_meta_title")}
          description={t("tag.main_meta_description")}
          openGraph={{
            url: `${process.env.NEXT_PUBLIC_SITE_ADDRESS}`,
            title: `${t("tag.main_meta_title")}`,
            description: `${t("tag.main_meta_description")}`,
            site_name: "moverii.de",
          }}
          twitter={{
            handle: "@handle",
            site: "@site",
            cardType: "summary_large_image",
          }}
        />
        <LogoJsonLd
          logo="/static/images/logo.svg"
          url={process.env.NEXT_PUBLIC_SITE_ADDRESS}
        />

        <main className={"bfront"}>
          <BannerTop />
          <div className="hidden md:block">
            <HomeSectioncomminity isHome />
          </div>
          <Discover isHome />
          <Destination isHome />
          <Experience />
          <OffersList
            data={offerData}
            hasFailed={hasFailed}
            getData={() => getData()}
          />
          <Testimonial />
          <Traveler />
          <div className="md:hidden pb-10">
            <HomeSectioncomminity isHome />
          </div>
        </main>
      </div>
    );
  })
);
IndexPage.Layout = BaseLayout;

export const getServerSideProps = async ({ locale }) => {
  if (process.env.NODE_ENV === "development") {
    await i18n?.reloadResources();
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])),
    },
  };
};

export default IndexPage;
