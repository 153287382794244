import classNames from "classnames";
import { observer } from "mobx-react";
import SliderDes from "../../../components/helper/slider";
import style from "../../../styles/main.module.css";

const Destination = observer((props) => {
  const Home_slider_data = [
    {
      id: 1,
      label: "Country",
      image:
        "https://api.moverii.net/cdn/statics/Homepage/Destinations/Portugal_Sportreise-Aktivurlaub.jpg?w=350",
      distinction_alias: "portugal",
      distinction_title_en: "Portugal",
      distinction_title_de: "Portugal",
    },
    {
      id: 2,
      label: "Country",
      image:
        "https://api.moverii.net/cdn/statics/Homepage/Destinations/Deutschland_Sportreisen-Aktivurlaub.jpg?w=350",
      distinction_alias: "deutschland",
      distinction_title_en: "Germany",
      distinction_title_de: "Deutschland",
    },
    {
      id: 3,
      label: "Country",
      image:
        "https://api.moverii.net/cdn/statics/Homepage/Destinations/Costa-Rica_Sportreise-Aktivurlaub.jpg?w=350",
      distinction_alias: "costa-rica",
      distinction_title_en: "Costa Rica",
      distinction_title_de: "Costa Rica",
    },
    {
      id: 4,
      label: "Country",
      image:
        "https://api.moverii.net/cdn/statics/Homepage/Destinations/Kanaren_Sportreisen-Aktivurlaub.jpg?w=350",
      distinction_alias: "kanarische-inseln",
      distinction_title_en: "Canary Islands",
      distinction_title_de: "Kanaren",
    },
    {
      id: 5,
      label: "Country",
      image:
        "https://api.moverii.net/cdn/statics/Homepage/Destinations/Österreich_Sportreisen-Aktivurlaub.jpg?w=350",
      distinction_alias: "oesterreich",
      distinction_title_en: "Austria",
      distinction_title_de: "Österreich",
    },
    {
      id: 6,
      label: "Country",
      image:
        "https://api.moverii.net/cdn/statics/Homepage/Destinations/Thailand_Sportreisen-Aktivurlaub.jpg?w=350",
      distinction_alias: "thailand",
      distinction_title_en: "Thailand",
      distinction_title_de: "Thailand",
    },
    {
      id: 7,
      label: "Country",
      image:
        "https://api.moverii.net/cdn/statics/Homepage/Destinations/Spanien_Sportreise-Aktivurlaub.jpg?w=350",
      distinction_alias: "spanien",
      distinction_title_en: "Spain",
      distinction_title_de: "Spanien",
    },
    {
      id: 8,
      label: "Country",
      image:
        "https://api.moverii.net/cdn/statics/Homepage/Destinations/Bali_Sportreisen-Aktivurlaub.jpg?w=350",
      distinction_alias: "bali",
      distinction_title_en: "Bali",
      distinction_title_de: "Bali",
    },
    {
      id: 9,
      label: "Country",
      image:
        "https://api.moverii.net/cdn/statics/Homepage/Destinations/Italien_Sportreisen-Aktivurlaub.jpg?w=350",
      distinction_alias: "italien",
      distinction_title_en: "Italy",
      distinction_title_de: "Italien",
    },
    {
      id: 10,
      label: "Country",
      image:
        "https://api.moverii.net/cdn/statics/Homepage/Destinations/Marokko_Sportreisen-Aktivurlaub.jpg?w=350",
      distinction_alias: "morocco",
      distinction_title_en: "Morocco",
      distinction_title_de: "Marokko",
    },
    {
      id: 11,
      label: "Country",
      image:
        "https://api.moverii.net/cdn/statics/Homepage/Destinations/Schweden__Sportreisen-Aktivurlaub.jpg?w=350",
      distinction_alias: "schweden",
      distinction_title_en: "Sweden",
      distinction_title_de: "Schweden",
    },
    // {
    //   id: 12,
    //   label: "Country",
    //   image:
    //     "https://api.moverii.net/cdn/images/multimedia/51wIK7fB6EPw56kFw68PoFtgMXgOocUBI5XVlbTJ.jpg?w=550",
    //   distinction_alias: "sri-lanka",
    //   distinction_title_en: "Sri Lanka",
    //   distinction_title_de: "Sri Lanka",
    // },
    {
      id: 13,
      label: "Country",
      image:
        "https://api.moverii.net/cdn/statics/Homepage/Destinations/Frankreich_Sportreisen-Aktivurlaub.jpg?w=350",
      distinction_alias: "frankreich",
      distinction_title_en: "France",
      distinction_title_de: "Frankreich",
    },
    {
      id: 14,
      label: "Country",
      image:
        "https://api.moverii.net/cdn/statics/Homepage/Destinations/Türkei_Sportreisen-Aktivurlaub.jpg?w=350",
      distinction_alias: "tuerkei",
      distinction_title_en: "Turkey",
      distinction_title_de: "Türkei",
    },
    {
      id: 15,
      label: "Country",
      image:
        "https://api.moverii.net/cdn/statics/Homepage/Destinations/Mallorca_Sport-Aktivreisen.jpg?w=350",
      distinction_alias: "?state[]=Mallorca",
      distinction_title_en: "Mallorca",
      distinction_title_de: "Mallorca",
    },
    {
      id: 16,
      label: "Country",
      image:
        "https://api.moverii.net/cdn/statics/Homepage/Destinations/Indien__Sportreisen-Aktivurlaub.jpg?w=350",
      distinction_alias: "indien",
      distinction_title_en: "India",
      distinction_title_de: "Indien",
    },
    {
      id: 17,
      label: "Country",
      image:
        "https://api.moverii.net/cdn/statics/Homepage/Destinations/Griechenland__Sportreisen-Aktivurlaub.jpg?w=350",
      distinction_alias: "griechenland",
      distinction_title_en: "Greece",
      distinction_title_de: "Griechenland",
    },
    {
      id: 18,
      label: "Country",
      image:
        "https://api.moverii.net/cdn/statics/Homepage/Destinations/Norwegen_Sportreisen-Aktivurlaub.jpg?w=350",
      distinction_alias: "norwegen",
      distinction_title_en: "Norway",
      distinction_title_de: "Norwegen",
    },
    {
      id: 19,
      label: "Country",
      image:
        "https://api.moverii.net/cdn/statics/Homepage/Destinations/Kroatien_Sportreisen-Aktivurlaub.jpg?w=350",
      distinction_alias: "croatia",
      distinction_title_en: "Croatia",
      distinction_title_de: "Kroatien",
    },
  ];

  return (
    <div
      className={classNames(
        style.destination_container,
        " py-[50px] md:py-[100px] select-none mt-[50px] lg:mt-[100px] px-3 md:px-0"
      )}
    >
      <SliderDes
        data={Home_slider_data}
        isHome={props?.isHome}
        shadowColor="from-[#e1f9fc]"
        arrowClass="new-arrow-offer"
      />
    </div>
  );
});
export default Destination;
