export const Routes = {
  home: "/",
  blog: `/blog`,
  tripfinder: `/tripfinder`,
  search: `/s/moverii`,
  blogView: (alias) => `/blog/view/${encodeURIComponent(alias)}`,
  blogCategory: (alias) => `/blog/category/${encodeURIComponent(alias)}`,
  bookingView: (alias, stats = "open") =>
    `/user/booking/${stats}?code=${encodeURIComponent(alias)}`,
  offerView: (alias) => `/offer/${encodeURIComponent(alias)}`,
};
