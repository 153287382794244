// Format price as following:
// German:
// EUR: 1.100,10 € - German Euro (Germany) - "EUR"
// USD: $1.100,10 - US Dollar (United States) - "USD"
// GBP: £1.100,10 - British Pound (United Kingdom) - "GBP"
// CHF: 1.100,10 CHF - Swiss Franc (Switzerland) - "CHF"

// English:
// EUR: € 1,100.10
// USD: $1,100.10
// GBP: £1,100.10
// CHF: 1,100.10 CHF

export const PriceFormatter = (price, currency, language, removeDecimal) => {
  // Convert price to number if it's a string and remove any non-numeric characters
  if (typeof price === "string") {
    price = parseFloat(price.replace(/[^\d.-]/g, ""));
  }

  // Validate price
  if (typeof price !== "number" || isNaN(price)) {
    price = 0;
  }

  // Format the price to a fixed decimal
  const formattedNumber = price.toFixed(2);

  // format numbers with RegEx
  const germanFormat = (numStr) => numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  const englishFormat = (numStr) =>
    numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Apply thousand and decimal separator based on language
  let [integerPart, decimalPart] = formattedNumber.split(".");
  if (language === "de") {
    integerPart = germanFormat(integerPart);
  } else {
    integerPart = englishFormat(integerPart);
  }

  // Reconstruct formatted number
  // let formattedPrice = `${integerPart}${
  //   language === "de" ? "," : "."
  // }${decimalPart}`;

  let formattedPrice = removeDecimal
    ? `${integerPart}`
    : `${integerPart}${language === "de" ? "," : "."}${decimalPart}`;

  if (formattedPrice <= 0) {
    formattedPrice = "0";
  }

  // Add the currency symbol
  if (language === "de") {
    if (currency === "EUR") {
      formattedPrice = `${formattedPrice} €`;
    }
  } else if (language === "en") {
    if (currency === "EUR") {
      formattedPrice = `€ ${formattedPrice}`;
    }
  }

  if (currency === "CHF") {
    formattedPrice = `${formattedPrice} CHF`;
  } else if (currency === "USD") {
    formattedPrice = `$${formattedPrice}`;
  } else if (currency === "GBP") {
    formattedPrice = `£${formattedPrice}`;
  }

  return formattedPrice;
};
