import {makeAutoObservable} from 'mobx'

export default class ProviderModel {
    constructor() {
        makeAutoObservable(this)
    }

    id = ''
    title = ''
    logo = ''
    rate = '0'
    reviews = '0'
    quote = ''

}
