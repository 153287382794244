import SearchBarContainer from "@components/SearchBar/SearchBarContainer";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import style from "../../../styles/main.module.css";
import MainSearch from "../../components/search/mainSearch";
import { useEffect, useState } from "react";
import { Form, Image } from "antd";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import useWindowSize from "src/hooks/useWindowSize";

const BannerTop = (props) => {
  const [searchPosition, setSearchPosition] = useState(false);
  const [openWorker, setopenWorker] = useState(false);

  const { t } = useTranslation("common");
  const { width } = useWindowSize();
  const isMobile = width < 1024;

  const _opener = (status) => {
    setSearchPosition(status);
    setopenWorker(status);

    const targetElement = document.querySelector("body");
    if (status) {
      disableBodyScroll(targetElement);
    } else {
      enableBodyScroll(targetElement);
    }
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
        _opener(false);
        setSearchPosition(false);
        setopenWorker(false);
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  const RENDER_NEW_SEARCH_BAR = false;

  return RENDER_NEW_SEARCH_BAR ? (
    <div
      className={classNames(
        style["banner-box"],
        "py-7 !h-[520px] md:!h-[550px] lg:!h-[530px]"
      )}
    >
      <div className={"flex flex-col container px-4 md:px-0 pb-44 md:pb-0"}>
        <div
          className={"flex flex-col space-y-2 text-center md:pt-[52px] sm:pt-0"}
        >
          <h1
            className={`text-white md:text-[52px] mt-10 md:mt-3 catamaran leading-tight text-[48px] sm:text-[52px] font-black drop-shadow-lg lg:pt-5`}
          >
            {t("frontpage.banner_text_title") || t(props.data.title)}
          </h1>
          <p
            className={`text-white text-2xl md:text-[26px] lg:text-[30px] font-semibold drop-shadow-lg`}
          >
            {t("frontpage.subtitle_banner")}
          </p>
        </div>

        {/* search bar */}
        <div className={`mt-[120px]`}>
          <SearchBarContainer type="home" />
        </div>
      </div>
    </div>
  ) : (
    <>
      {searchPosition && isMobile ? (
        <div className="absolute !h-full top-0 bottom-0 left-0 right-0 bg-white !z-50 overflow-hidden">
          <div className="bg-white !z-50">
            <MainSearch
              onOpen={_opener}
              openWorker={openWorker}
              setSearchPosition={setSearchPosition}
              opened={true}
            />
            <div onClick={() => _opener(false)}>close</div>
          </div>
        </div>
      ) : (
        <>
          {/* OLD SEARCH BAR */}
          {/* desktop */}
          <div
            className={classNames(
              style["banner-box"],
              "py-7 !h-[520px] md:!h-[550px] lg:!h-[530px] !hidden md:!block"
            )}
          >
            {searchPosition && (
              <div
                onClick={() => {
                  _opener(false);
                }}
                className="bg-black/80 fixed z-40 w-full left-0 right-0 bottom-0 0 top-0 1 "
              ></div>
            )}
            <div
              className={
                "flex flex-col justify-between container px-4 md:px-0 pb-44 md:pb-0"
              }
            >
              <div
                className={
                  "flex flex-col space-y-2 text-center md:pt-[52px] sm:pt-0"
                }
              >
                <h1
                  className={`text-white md:text-[52px] mt-10 md:mt-3 catamaran leading-tight text-[48px] sm:text-[52px] font-black drop-shadow-lg lg:pt-5`}
                >
                  {t("frontpage.banner_text_title") || t(props.data.title)}
                </h1>
                <p
                  className={`text-white text-2xl md:text-[26px] lg:text-[30px] font-semibold drop-shadow-lg`}
                >
                  {t("frontpage.subtitle_banner")}
                </p>
              </div>
              <div
                className={`flex justify-center z-50 transform duration-300 ${
                  searchPosition
                    ? "lg:absolute lg:-top-[110px] lg:left-0 lg:right-0"
                    : "lg:mt-0"
                } !mt-[150px] sm:!mt-[100px]`}
              >
                <Form
                  className={"form-inner w-full mb-4 first-color-s"}
                  name="basic"
                  onFinish={() => {}}
                  onFinishFailed={() => {}}
                  autoComplete="off"
                >
                  <MainSearch
                    onOpen={_opener}
                    openWorker={openWorker}
                    setSearchPosition={setSearchPosition}
                  />
                </Form>
              </div>
            </div>
          </div>

          {/* mobile */}
          <div className="w-full md:hidden">
            <div className="w-full h-[220px] bg-[#FDD59E] flex justify-center items-center p-4">
              <h1 className="text-center catamaran text-[32px] text-[#2B2D42] font-black -mt-5">
                {t("frontpage.banner_text_title_mobile")}
              </h1>
            </div>
            {/* search box */}
            <div className="px-6 w-full">
              <div
                onClick={() => _opener(true)}
                className="w-full border-[3px] border-[#12A3BA] shadow-sm rounded-[100px] min-h-[62px] flex items-center justify-start gap-2 px-4 -mt-[30px] bg-white py-2 cursor-pointer"
              >
                <div className="w-[20px] h-[20px] flex">
                  <Image
                    src="/icon/search.svg"
                    alt="search"
                    preview={false}
                    loading="eager"
                    width={20}
                    height={20}
                  />
                </div>
                <p className="text-[#2B2D42] text-base font-semibold">
                  {t("frontpage.banner_search_box_text_mobile")}
                </p>
              </div>
            </div>

            {/* Image composition */}
            <div className="w-full h-[450px] max-w-lg mx-auto">
              <div className="w-full h-full md:py-0 scale-75 sm:scale-90 md:scale-75 lg:scale-95 relative flex justify-center">
                <span className="absolute z-30 w-[200px] md:h-[150px] lg:h-[183px] top-[20px] md:top-[10px] lg:top-[20px] -left-[30px] lg:left-[0px]">
                  <Image
                    src={
                      process.env.NEXT_PUBLIC_IMAGE_ADDRESS +
                      "cdn/statics/Homepage/Newsletter/moverii-Sportreisen-Aktivurlaub-Surfen.jpg"
                    }
                    alt="Moverii Newsletter 1"
                    preview={false}
                    fallback={"/static/images/placeholder/provider.jpg"}
                    loading="lazy"
                    width={"100%"}
                    height={"100%"}
                    className="object-center object-cover rounded-[20px] shadow-md"
                  />
                </span>
                <span className="z-10 md:w-[250px] lg:w-[338px] h-[410px]">
                  <Image
                    src={
                      process.env.NEXT_PUBLIC_IMAGE_ADDRESS +
                      "cdn/statics/Homepage/Newsletter/moverii-Sportreisen-Aktivurlaub-Wandern.jpg"
                    }
                    alt="Moverii Newsletter 2"
                    preview={false}
                    fallback={"/static/images/placeholder/provider.jpg"}
                    loading="lazy"
                    width={"100%"}
                    height={"100%"}
                    className="w-[338px] h-full object-center object-cover rounded-[20px] shadow-md"
                  />
                </span>
                <span className="absolute z-30 bottom-1 w-[170px] h-[132px] -right-[30px] sm:right-[10px]">
                  <Image
                    src={
                      process.env.NEXT_PUBLIC_IMAGE_ADDRESS +
                      "cdn/statics/Homepage/Newsletter/moverii-Sportreisen-Aktivurlaub-Yoga.jpg"
                    }
                    alt="Moverii Newsletter 3"
                    preview={false}
                    fallback={"/static/images/placeholder/provider.jpg"}
                    loading="lazy"
                    width={"100%"}
                    height={"100%"}
                    className="object-center object-cover rounded-[20px] shadow-md"
                  />
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default BannerTop;
