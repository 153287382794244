import { GoogleOAuthProvider } from "@react-oauth/google";
import { CurrencyProvider } from "src/hooks/CurrencyContext";
import { FavoriteProvider } from "src/hooks/FavoriteContext";
import "@styles/antd.css";
import "@styles/chat.css";
import { ConfigProvider } from "antd";
import { Provider } from "mobx-react";
import "moment/locale/de";
import { appWithTranslation } from "next-i18next";
import nextI18nextConfig from "next-i18next.config";
import { DefaultSeo } from "next-seo";
import App from "next/app";
import Head from "next/head";
import { Router } from "next/router";
import { I18nextProvider } from "react-i18next";
import { AnimatePresence } from "framer-motion";
import NProgress from "react-nprogress";
import "react-vertical-timeline-component/style.min.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "swiper/css";
import "swiper/css/effect-cards";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import i18n from "../i18next";
import initializeStore from "../src/data/base/CoreStore";
import "../styles/globals.css";
import "../styles/style.css";

Router.events.on("routeChangeStart", (url) => {
  NProgress.start();
});
Router.events.on("routeChangeComplete", () => {
  NProgress.done();
});
Router.events.on("routeChangeError", () => {
  NProgress.done();
});

const Noop = ({ children }) => <>{children}</>;
ConfigProvider.config({
  theme: {
    primaryColor: "#25b864",
  },
});
class MyApp extends App {
  static async getInitialProps(appContext) {
    const { Component, ctx } = appContext;
    let pageProps = {};
    const query = ctx.query;
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    const mobxStore = initializeStore(true, null, ctx?.req?.cookies);
    appContext.ctx.mobxStore = mobxStore;
    const appProps = await App.getInitialProps(appContext);

    return {
      ...appProps,
      pageProps: pageProps || {},
      query,
      mobxStore: mobxStore,
      cookie: ctx?.req?.cookies,
    };
  }

  constructor(props) {
    super(props);
    const isServer = typeof window === "undefined";

    this.mobxStore = isServer
      ? props.mobxStore
      : initializeStore(isServer, props.mobxStore, props.cookie);
  }

  render() {
    const { Component, pageProps = {} } = this.props;
    const Layout = Component.Layout || Noop;

    return (
      <>
        <Head>
          <meta name="msapplication-TileColor" content="#fe9e1b" />
          <meta
            name="msapplication-TileImage"
            content="/static/fav/ms-icon-144x144.png"
          />
          <meta name="theme-color" content="#fe9e1b" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />
        </Head>

        <GoogleOAuthProvider
          clientId={process.env.NEXT_PUBLIC_GOOGLE_AUTO_LOGIN_CLIENT_ID}
        >
          <CurrencyProvider>
            <Provider CoreStore={this.mobxStore}>
              <FavoriteProvider>
                <AnimatePresence>
                  <I18nextProvider i18n={i18n}>
                    <DefaultSeo
                      title={"moverii"}
                      openGraph={{
                        type: "website",
                        locale: i18n.language === "en" ? "en_IE" : "de",
                        url: "https://www.moverii.de/",
                        site_name: "mvoerii",
                      }}
                      twitter={{
                        handle: "@handle",
                        site: "@site",
                        cardType: "summary_large_image",
                      }}
                    />
                    {!!pageProps && (
                      <Layout {...pageProps}>
                        <Component {...pageProps} />
                      </Layout>
                    )}
                  </I18nextProvider>
                </AnimatePresence>

                <noscript>
                  <iframe
                    src="https://www.googletagmanager.com/ns.html?id=GTM-WJ2QVZM"
                    height="0"
                    width="0"
                    style={{ display: "none", visibility: "hidden" }}
                  ></iframe>
                </noscript>
              </FavoriteProvider>
            </Provider>
          </CurrencyProvider>
        </GoogleOAuthProvider>
      </>
    );
  }
}

export default appWithTranslation(MyApp, nextI18nextConfig);
