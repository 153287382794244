import TailwindToggle from "@components/search/TailwindToggle";
import DateHelper from "@helper/DateHelper";
import { Skeleton, DatePicker, Image } from "antd";
import { useState, useEffect } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;

const SearchCalendar = ({
  calendarData,
  isLoading,
  setSelectedMonth,
  selectedMonth,
  setStartDate,
  setEndDate,
  endDate,
  startDate,
  setOpenDateBox,
  setIsDateSelected,
}) => {
  const [isFlexibale, setIsFlexibale] = useState(true);

  const { t, i18n } = useTranslation("common");
  const now = moment();

  // specific calendar data
  const isFullAccess = calendarData?.specific?.full_access_calender;
  const workDays = calendarData?.specific?.work_dates || [];
  const excludeDates = calendarData?.specific?.exclude_dates || [];
  const validDates = calendarData?.specific?.valid_dates || [];

  useEffect(() => {
    // Clear the written value if no month was selected
    if (selectedMonth?.length == 0 && !startDate && !endDate) {
      setIsDateSelected(false);
    }
  }, [selectedMonth]);

  const handleDisabledDates = (current) => {
    if (!current) return;

    // Disable past dates - before today
    if (current.isBefore(moment(now).endOf("day"))) return true;

    // Disable all days except workdays (allow only workdays)
    if (workDays?.length > 0) {
      return !workDays.some((date) => date - 1 == moment(current).format("d"));
    }

    // Full access calendar logic
    if (isFullAccess && excludeDates) {
      // Disable excluded dates
      return excludeDates?.includes(moment(current).format("YYYY-MM-DD"));
    }

    // Restricted calendar logic: only allow validDates
    if (validDates?.length > 0) {
      return !validDates?.includes(moment(current).format("YYYY-MM-DD"));
    }

    // Allow all dates if no restrictions
    return false;
  };

  const cellRender = (current) => {
    return (
      <div
        className={`ant-calendar-date ant-picker-cell-inner`}
        title={moment(current).format(
          i18n.language === "en" ? "YYYY/MM/DD" : "YYYY.MM.DD"
        )}
      >
        {current.date()}
      </div>
    );
  };

  return (
    <div className="w-full h-full flex flex-col items-center justify-center">
      <div className="mb-0 flex w-full items-center justify-center mt-2">
        <TailwindToggle setValue={setIsFlexibale} value={isFlexibale} />
      </div>

      {!isLoading ? (
        isFlexibale ? (
          !calendarData?.flexible?.full_access &&
          calendarData?.flexible?.valid_month.length == 0 ? (
            // No date available
            <div className="w-full h-full flex flex-col items-center justify-center mt-2">
              <Image
                src="/icon/search/no-data.png"
                alt="no data!"
                preview={false}
                width={"200px"}
                height={"200px"}
                loading="lazy"
              />
              <p className="max-w-[40%] text-center text-wrap !text-black font-medium">
                {t("search.searchbar_no_data_text_dates")}
              </p>
            </div>
          ) : (
            // Flexible - Month selection
            <div className="w-full grid grid-cols-4 lg:grid-cols-6 gap-x-3 gap-y-4 px-5 mt-7 lg:mt-12">
              {_.range(1, 13).map((monthNum) => {
                const month = moment().add(monthNum, "M").format("YYYY-MM");

                // if full_access is false, only allow selecting valid_month
                const isBlocked =
                  !calendarData?.flexible?.full_access &&
                  calendarData?.flexible?.valid_month?.length > 0 &&
                  !calendarData?.flexible?.valid_month?.some(
                    (mo) => moment(mo).format("YYYY-MM") === month
                  );

                return (
                  <div
                    key={monthNum}
                    className={`border border-gray-500 rounded-3xl w-full h-[80px] shadow-sm flex flex-col items-center justify-center hover:shadow-md transition-all duration-200 px-2 text-sm ${
                      isBlocked
                        ? // Block month if not available
                          "bg-gray-70 !cursor-not-allowed"
                        : "cursor-pointer bg-white hover:bg-gray-30"
                    } ${selectedMonth?.includes(month) ? "!bg-[#fdd59e]" : ""}`}
                    onClick={() => {
                      if (!isBlocked) {
                        setIsDateSelected(true);

                        // Check if the month was already selected
                        if (selectedMonth?.includes(month)) {
                          // Filter out the selected item
                          const filteredItems = selectedMonth?.filter(
                            (m) => m !== month
                          );
                          setSelectedMonth(filteredItems);
                        } else {
                          // Add new selected month
                          setSelectedMonth([...selectedMonth, month]);
                        }

                        // Clear specific date value
                        setStartDate(null);
                        setEndDate(null);
                      }
                    }}
                  >
                    <p className="font-bold">
                      {moment().add(monthNum, "M").format("MMMM")}
                    </p>
                    <p className="font-normal">
                      {moment().add(monthNum, "M").format("YYYY")}
                    </p>
                  </div>
                );
              })}
            </div>
          )
        ) : (
          // Specific date selection - Range picker
          <>
            {isFullAccess == false && validDates?.length == 0 ? (
              // No date available
              <div className="w-full h-full flex flex-col items-center justify-center mt-2">
                <Image
                  src="/icon/search/no-data.png"
                  alt="no data!"
                  preview={false}
                  width={"200px"}
                  height={"200px"}
                  loading="lazy"
                />
                <p className="max-w-[40%] text-center text-wrap !text-black font-medium">
                  {t("search.searchbar_no_data_text_dates")}
                </p>
              </div>
            ) : (
              <div
                onClick={(e) => e.stopPropagation()}
                className="flex w-full !relative items-start z-50 justify-end main-filter-date-picker h-80 lg:h-[400px]"
              >
                <RangePicker
                  open={true}
                  format={DateHelper.getFormat()}
                  size="large"
                  value={[startDate, endDate]}
                  style={{ width: "0", visibility: "hidden" }}
                  disabledDate={handleDisabledDates}
                  dropdownClassName={"borderLess !border-none"}
                  onChange={(e) => {
                    // Update states
                    setStartDate(e[0] || null);
                    setEndDate(e[1] || null);
                    setOpenDateBox(e ? false : true);
                    setIsDateSelected(e ? true : false);

                    // Clear month value
                    setSelectedMonth([]);
                  }}
                  bordered={false}
                  placement="bottomRight"
                  className="!border-none !absolute !-top-[25px] !right-[20%]"
                  monthCellRender={(date) => date.format("MMMM")}
                  dateRender={cellRender}
                  panelRender={(panel) => (
                    <div className="w-full relative">
                      <div className="w-full z-[9999]">{panel}</div>
                      <span className="!absolute bottom-0 w-full h-[60px] !bg-white !z-[999999]" />
                    </div>
                  )}
                />
              </div>
            )}
          </>
        )
      ) : (
        //  Loading effect
        <div className="w-full grid grid-cols-6 gap-3 px-5 mt-12">
          {_.range(0, 12).map((item) => (
            <Skeleton.Button
              active
              className="!w-full !h-[100px] !rounded-3xl !shadow-md"
              key={item}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchCalendar;
