import { Image, Rate } from "antd";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import style from "../../../../styles/main.module.css";

export default function Testimonial() {
  const { t } = useTranslation("common");

  const prevRef = useRef();
  const nextRef = useRef();

  const [swiper, setSwiper] = useState();

  const testimonials_data = [
    {
      id: 1,
      description:
        "Diese Reise werde ich nie vergessen, es war unglaublich schön und hat Körper und Seele so gut getan! ☺️ Chris hat für uns alles möglich gemacht und war einfach klasse! 💪🏼 Ich kann diese Erfahrung nur jedem ans Herz legen 🧘🏻‍♀️🏔🪂☀️",
      image:
        "https://api.moverii.de/cdn/images/multimedia/aOFJOjeSS-2_50oT9Sld1596476862.jpg",
      name: "Laura",
      offer_title:
        "Abwechslungsreiches Outdoor- & Yoga-Retreat auf 1800 Metern\r\n",
      offer_url: "globeseeker-sommer-outdoor-und-yoga-retreat",
      rate: 5,
    },
    {
      id: 2,
      description:
        "Schönes Hotel mit Ausblick auf den Gletscher und perfekt für Wanderungen und Wintersport. Toll sind auch die drei verschiedenen Wellness-Bereiche. Wer sich eine kleine private Auszeit gönnen möchte, kann einen der Wellness-Bereiche für sich alleine / zu Zweit buchen.",
      image:
        "https://api.moverii.de/cdn/images/multimedia/1J4JV9HK7QONtjiKW3mt1597879355.jpeg",
      name: "Giovanna",
      offer_title: "Aktivurlaub im Pitztaler Sport- und Wellnesshotel\r\n",
      offer_url: "aktivurlaub-im-sport-und-wellnesshotel-pitztal",
      rate: 5,
    },
    {
      id: 3,
      description:
        "Der Surfkurs war super, wir haben viel beigebracht bekommen und sind an sehr gute Surfspots gefahren. Im Hostel selbst herrscht eine super entspannte Atmosphäre und ich habe dort sehr viele tolle Menschen getroffen. Wer das Surfleben liebt und kennt, kommt dort auf seine Kosten!",
      image:
        "https://api.moverii.de/cdn/images/multimedia/A883pKn3uVkaFoSvXcrnWeKcoHSKWavCf1GtiTXl.jpg",
      name: "Johanna",
      offer_title: "Surfcamp in Corralejo: chill, surf & meet new people",
      offer_url: "sagres-natura-surf-camp-sagres-portugal",
      rate: 5,
    },
    {
      id: 4,
      description:
        "Ich habe mich auf Anhieb sehr wohl gefühlt, die offene und herzliche Art der Gastgeber war toll. Es ist alles sehr liebevoll eingerichtet und organisiert, alle haben einen lieben Umgang miteinander und mit den Gästen. Das Essen war unglaublich lecker, da Heiko Koch ist und jeden Tag ein leckeres Essen zaubert. Rundum ist der Retreat genau das, was ich gebraucht habe. Die Zusammenstellung aus Surfen, Yoga, Massage usw war perfekt. Man hatte jedoch auch ausreichend Zeit sich auszuruhen oder die Insel etwas zu erkunden. Ich kann es nur weiterempfehlen.",
      image:
        "https://api.moverii.de/cdn/images/multimedia/bJehwcuirCM4fZPEC4561638043124.jpg",
      name: "Vane",
      offer_title:
        "Yoga und Surfen in der Künstlerstadt Lajares auf Fuerteventura",
      offer_url: "seven-lemons-yoga-surf-retreat-lajares-fuerteventura",
      rate: 5,
    },
    {
      id: 5,
      description:
        "Moverii hat eine Reise für mich gefunden, die genau auf meine Bedürfnisse angepasst war. Das Team ist unglaublich freundlich und kompetent. Ich habe umgehend positive Rückmeldung bei Nachfragen bekommen. Auch das Preis-/Leistungsverhältnis stimmte. Meine Reise war ein tolles Erlebnis und der Service von Moverii war perfekt. Auch die Kommunikation mit Steve war Einwandfrei. Die Unterkunft und das Essen haben meine Erwartungen übertroffen. Steve ist ein hervorragender Trainer. Sehr ausgezeichnete fachliche Kenntnisse, super Motivation, sehr emphatisch und Aufmerksam. Er geht individuell auf die Bedürfnisse der Kunden ein.",
      image:
        "https://api.moverii.de/cdn/images/multimedia/3I8b4pspMxGV4WJmZgWm1580679441.jpg",
      name: "Maren",
      offer_title: "Teneriffa Bootcamp: Fit werden im Urlaubsparadies",
      offer_url: null,
      rate: 5,
    },
    {
      id: 6,
      description:
        "Wir haben uns von der ersten Minute an sehr wohl gefühlt und es war alles top sauber. Leo und sein Team waren jederzeit kompetent und hilfsbereit. Auch das Frühstück war top! 10/10 - würde wieder hin fahren!",
      image:
        "https://api.moverii.de/cdn/images/multimedia/2CsWFUbU1Dfr3HDusOei1568720063.jpg",
      name: "Mathias",
      offer_title: "Surfcamp mit Hostel-Vibes im Surfer-Hotspot Tamarindo",
      offer_url: "la-botella-de-leche-surfcamp-tamarindo-costa-rica",
      rate: 5,
    },
    {
      id: 7,
      description:
        "Dieses Camp ist mehr als nur ein Camp. Es ist ein Ort der Befreiung und des Fortschrittes sowohl im Körper als auch im Geist. Eine sehr familiäre Umgebung, eine schicke Unterkunft und spitzen Essen erwartet euch. Ich kann es nur weiterempfehlen, den Rest müsst ihr tun.👌🏽 10 von 10",
      image:
        "https://api.moverii.de/cdn/images/multimedia/Mv9Lr7fNZREqktVzQ5cl1578176864.jpg",
      name: "Erik",
      offer_title: "Costa Blanca Boxcamp: Trainiere wie die Boxprofis",
      offer_url: "punchcamp-spain-boxcamp-guadalest-costa-blanca",
      rate: 5,
    },
    {
      id: 8,
      description:
        "Wunderschöne Natur, inspirierendes Yoga, mega gutes Essen und eine schöne Atmosphäre! Ich werde auf jeden Fall wiederkommen!",
      image:
        "https://api.moverii.de/cdn/images/multimedia/-Am1YgNu3P2IP9vRRD_v1605009594.jpg",
      name: "Sina",
      offer_title: "Erholsame Yoga-Auszeit in der Chiemgauer Bergwelt",
      offer_url: "indigourlaub-yoga-auszeit-chiemgauer-bergwelt-deutschland",
      rate: 5,
    },
    {
      id: 9,
      description:
        "familiär, einzigartig, gemütlich, Gastgeber waren sehr bemüht und sehr liebevoll!",
      image:
        "https://api.moverii.de/cdn/images/multimedia/EdfmElPMg75iSI5--G-R1623947376.jpg",
      name: "Chloé",
      offer_title: "Familiäres Surfhaus im unberührten Norden Portugals",
      offer_url: "estela-surfhostel-familiaerer-surfurlaub-estela-portugal",
      rate: 5,
    },
  ];

  useEffect(() => {
    if (swiper) {
      swiper?.navigation.init();
      swiper?.navigation.update();
    }
  }, [swiper]);

  return (
    <div
      className={`${classNames(
        style.travel_pos,
        "mt-[50px] lg:mt-[100px] px-4 md:px-0"
      )}`}
    >
      <div className={"container mx-auto px-2 lg:px-0"}>
        <h3
          className={
            "font-black md:text-center catamaran text-[28px] pb-[24px] md:pb-[30px]"
          }
        >
          {t("frontpage.testimonial")}
        </h3>

        <Swiper
          nextButton={nextRef}
          prevButton={prevRef}
          onSwiper={setSwiper}
          grabCursor={true}
          loop={true}
          modules={[Navigation]}
          className="mySwiper mb-5 md:mb-0"
          initialSlide={0}
          on
          observer
          observeParents
          navigation={{
            nextEl: nextRef?.current,
            prevEl: prevRef?.current,
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            560: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            700: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            910: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
          }}
        >
          {testimonials_data?.map((v, i) => {
            return (
              <SwiperSlide key={i}>
                <a
                  href={v?.offer_url ? `/offer/${v?.offer_url}` : "#"}
                  target="_blank"
                >
                  <div className={"box-testo"}>
                    <div className="justify-between py-1 flex">
                      <div className="flex flex-col md:flex-row items-start md:items-center space-x-1 pb-1">
                        <h6 className="font-bold text-lg pr-1">{v.name}</h6>
                        <span className="!-ml-[1px] md:ml-0">
                          <Rate
                            style={{ fontSize: "16px", color: "#ffc312" }}
                            value={v.rate}
                            disabled={true}
                            classNames="flex"
                          />
                        </span>
                      </div>
                    </div>

                    <p className={style.des_travel}>{v.description}</p>

                    <div className="flex border-t pt-4 mt-2 border-gray-50/10 items-center gap-3">
                      <div className="w-[40px] h-[40px]">
                        <Image
                          src={
                            v?.image ||
                            "/static/images/placeholder/provider.jpg"
                          }
                          alt={v?.offer_title}
                          className="object-center object-cover !rounded-lg"
                          preview={false}
                          width={40}
                          height={40}
                          fallback={"/static/images/placeholder/provider.jpg"}
                          loading="lazy"
                        />
                      </div>

                      <p className="font-bold">{v.offer_title}</p>
                    </div>
                  </div>
                </a>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className={"flex justify-center md:mt-5"}>
          <div className={"flex flex-row space-x-4 items-center"}>
            <div className={" cursor-pointer w-8"} ref={prevRef}>
              <img src={"/static/icons/arl.svg"} alt="arrow left icon" />{" "}
            </div>
            <div className={" cursor-pointer w-8"} ref={nextRef}>
              <img src={"/static/icons/arr.svg"} alt="arrow right icon" />{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
