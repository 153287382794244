import OfferItem from "@components/offer_list/OfferItem";
import OfferModel from "@model/OfferModel";
import ProviderModel from "@model/ProviderModel";
import _ from "lodash";
import { set } from "mobx";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import style from "../../styles/main.module.css";

const PopularSlider = (props) => {
  const { showSectionTitle = true, arrowClass } = props;
  const { t } = useTranslation("common");
  const [offers, setOffers] = useState([]);
  const properties = {
    duration: 500,
    slidesToShow: 4.3,
    slidesToScroll: 4,
    autoplay: false,
    dots: true,
    infinite: false,
    dotsClass: "slick-dots slick-thumb slick-dots-52",
    customPaging: (i) => {
      return <div className="dot"></div>;
    },
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: 3.2,
          centerMode: false,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1020,
        settings: {
          slidesToShow: 2.5,
          centerMode: false,
          slidesToScroll: 2,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerMode: false,
          slidesToShow: 1.3,
          slidesToScroll: 1,
          centerPadding: 0,
          initialSlide: 1,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: false,
          centerPadding: 0,
          slidesToShow: 1.2,
          dots: false,
          arrows: false,

          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const ll = [];
    props?.data?.map((ia, i) => {
      const pList = [];
      const off = new OfferModel();
      set(off, ia);
      const provider = new ProviderModel();
      set(provider, ia.provider);
      off.provider = provider;

      let basePrice = 0;
      try {
        ia.rooms.map((ac) => {
          if (typeof ac == "object") {
            ac.prices.map((rp) => {
              try {
                rp.map((p) => {
                  pList.push(p);
                });
              } catch (e) {
                ac.prices.map((rp) => {
                  pList.push(rp);
                });
              }
            });
          }
        });
      } catch (e) {
        console.log(e);
      }

      const durationMin = _.minBy(pList, function (o) {
        return o.duration;
      });
      const minDurration = [];
      if (durationMin != undefined) {
        pList.map((d) => {
          if ((d.duration = durationMin.duration)) {
            minDurration.push(d);
          }
        });

        basePrice = _.minBy(minDurration, function (o) {
          return o.pricePerPeople;
        });
      }

      set(off, { basePrice: basePrice, prices: pList });
      ll.push(off);
    });

    setOffers(ll);
  }, []);
  return (
    <div
      className={` ${style.box_slider_ppl}`}
      style={{ position: "relative" }}
    >
      {showSectionTitle ? (
        <div className={"md:text-center pb-[24px] md:pb-[30px]"}>
          <h2 className={"font-black text-[28px] catamaran"}>
            {t("frontpage.title_offers")}
          </h2>
        </div>
      ) : null}
      <div
        className="absolute h-full hidden sm:flex w-40  bg-gradient-to-l from-white right-0 top-0 z-10 max-w-md"
        style={{ width: "18rem !important" }}
      ></div>
      <Slider {...properties} className={`${arrowClass}`}>
        {offers?.map((v, i) => {
          return <OfferItem key={i} data={v} />;
        })}
      </Slider>
    </div>
  );
};

export default PopularSlider;
