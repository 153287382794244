//! Required
import {
  Checkbox,
  ConfigProvider,
  DatePicker,
  Divider,
  Input,
  Select,
} from "antd";
import moment from "moment";
import { useRouter } from "next/router";
import { useEffect, useMemo, useRef, useState } from "react";
//! DatePicker
import deLocale from "antd/lib/locale-provider/de_DE";
import locale from "antd/lib/locale-provider/en_GB";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

//! Icons
import DateHelper from "@helper/DateHelper";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import request from "../../libs/request/request";
import { SearchIcon } from "./ComponentIcon";
import Placeholder from "./Placeholder";
import TailwindToggle from "./TailwindToggle";
import useWindowSize from "src/hooks/useWindowSize";

// Navbar Drawer - For Mobile
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

// Close Icon
import { SearchOutlined } from "@ant-design/icons";
import { IoCloseCircleSharp } from "react-icons/io5";

const { RangePicker } = DatePicker;

//! Comp
const MainSearch = observer((props) => {
  const { t, i18n } = useTranslation("common");

  const [shadow1, setShadow1] = useState(false);
  const [shadow2, setShadow2] = useState(false);
  const [shownDate, setShownDate] = useState(false);
  const [value, setValue] = useState("");
  const [months, setMonths] = useState("");
  const [toggle, setToggle] = useState(true);
  const [countries, setCountries] = useState(undefined);
  const [hardSport, setHardSport] = useState([]);
  const [sports, setSports] = useState([]);
  const [countriesSearch, setCountriesSearch] = useState("");
  const [sportsSearch, setSportsSearch] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedSport, setSelectedSport] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showDate, setShowDate] = useState([]);
  const [openBox, setOpenBox] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState(false);
  const [mobilePageIndex, setMobilePageIndex] = useState(1);
  // For Mobile Open/close
  const [isOpen, setIsOpen] = useState(
    props.opened == undefined ? false : true
  );
  const [sportTitle, setSportTitle] = useState("");
  const [typeValueDestination, setTypeValueDestination] = useState("");
  const [typeValueSport, setTypeValueSport] = useState("");
  const [headerSearch, setHeaderSearch] = useState(true);
  const [showMobileCalendar, setShowMobileCalendar] = useState(false);
  const [sportId, setSportId] = useState(0);
  const [destId, setDestId] = useState(0);
  const [dateBoxOpen, setDateBoxOpen] = useState(false);
  const [calendarData, setCalendarData] = useState(undefined);
  const [workDay, setWorkDay] = useState(calendarData?.specific?.work_dates);
  const [desType, setDesType] = useState("country");
  const [subSport, setSubSport] = useState([]);
  const [showSubSport, setShowSubSport] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [destVal, setDestVal] = useState();
  const [sportVal, setSportVal] = useState();
  const [isSearching, setIsSearching] = useState(false);

  const refdis = useRef();
  const { width } = useWindowSize();
  const isSmScreen = width < 1024;

  const setOpenBoxEvent = async (status) => {
    setOpenBox(status);
    const targetElement = document.querySelector("body");
    await props.setSearchPosition(status);
    await props.onOpen(status);
    if (status) {
      targetElement.scrollIntoView({ behavior: "smooth" });
      disableBodyScroll(targetElement);
    } else {
      enableBodyScroll(targetElement);
    }
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  };

  const enableScroll = () => {
    const targetElement = document.querySelector("body");
    enableBodyScroll(targetElement);
  };

  const _changeStateInSport = (data) => {
    setCountries(undefined);
    clearTimeout(searchTimeout);
    const asearchTimeout = setTimeout(() => {
      request
        .get("/search-data", { params: data })
        .then((result) => {
          setCountries(result.data);
        })
        .catch((error) => console.log(error.response));
    }, 10);
    setSearchTimeout(asearchTimeout);
  };

  const _searchCategory = (text) => {
    // For Type-in search - Sport
    if (text === undefined || text === null || text === "") {
      setSports(originalData?.sports);
      setSubSport(originalData?.subsports);
    } else {
      setIsSearching(true);
      setSports([]);
      clearTimeout(searchTimeout);
      const asearchTimeout = setTimeout(() => {
        request
          .get("/offer/countries", { params: { category: text } })
          .then((result) => {
            setIsSearching(false);
            setCountries(undefined);
            setCountries(result?.data?.countries);
            setHardSport(result?.data?.sports);
            setSports(result?.data?.sports);
            setSubSport(result?.data?.subsports);
          })
          .catch((error) => {
            setIsSearching(false);
            setCountries(originalData);
            setSports(originalData?.sports);
          });
      }, 500);
      setSearchTimeout(asearchTimeout);
    }
  };

  const _searchCountry = (text) => {
    // For Type-in search - Country
    if (text === "" || text === undefined) {
      setCountries(originalData);
    } else {
      setSports(undefined);
      setCountries(undefined);
      clearTimeout(searchTimeout);
      const asearchTimeout = setTimeout(() => {
        request
          .get(
            `/offer/countries${
              selectedSport ? `?category=${selectedSport}` : ""
            }`,
            { params: { country: text } }
          )
          .then((result) => {
            setCountries(result?.data);
          })
          .catch((error) => {
            setCountries(originalData);
          });
      }, 500);
      setSearchTimeout(asearchTimeout);
    }
  };

  const handleChange = async (e) => {
    await setShadow1(false);
    await setShadow2(true);
    await refdis.current.focus();
  };

  const destinationHandler = async (e) => {
    // !isSmScreen && setSelectedCountry(e);
    await setShadow2(false);
    await refdis.current.blur();
    await setShownDate(true);
  };

  const onChange4 = (value) => {
    if (value.length) {
      const month = value.map((d) => moment(`2020/${d}/1`).format("MMMM"));
      setMonths(month);
    }
    setValue(value);
  };
  const router = useRouter();

  useEffect(() => {
    setCountries([]);
    request
      .get("/search-latest")
      .then((result) => {
        setHardSport(result?.data?.sports);
        setSports(result?.data?.sports);
        setCountries(result?.data);
        setSubSport(result?.data?.subsports);
        setOriginalData(result?.data);
      })
      .catch((error) => console.log(error.response));
  }, [countriesSearch, sportsSearch]);

  // Getting available/unavailable dates
  useEffect(() => {
    if (dateBoxOpen || shownDate) {
      request
        .get(
          `/search-travel-dates?sport_id=${sportId}&destination_id=${destId}&destination_type=${desType}`
        )
        .then((result) => {
          setCalendarData(result?.data);
          setWorkDay(calendarData?.specific?.work_dates);
        })
        .catch((error) => console.log(error.response));

      return setTimeout(() => {
        setDateBoxOpen(false);
      }, 1000);
    }
  }, [dateBoxOpen, shownDate]);

  useMemo(() => {
    if (startDate && endDate) {
      setTimeout(() => {
        setShownDate(false);
      }, 500);
    }
  }, [startDate, endDate]);

  // Executes whenever Esc key is pressed
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Escape") {
        event.preventDefault();
        setOpenBox(false);
        setSearchTimeout(true);
        setShownDate(false);
        props.onOpen(false);
        props.setSearchPosition(false);
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    // clean up event listener
    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, []);

  // Toggle function for opening/closing Drawer
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };

  // Sets new selected sport
  const handleClick = async (e) => {
    setSportTitle(e);
    setMobilePageIndex((prev) => prev + 1);
  };

  // Sets new selected Destination
  const handleClickDestination = async (e) => {
    setShownDate(true);
    setMobilePageIndex((prev) => prev + 1);
  };

  // Go next
  const goNext = () =>
    mobilePageIndex === 1
      ? props.onOpen(false)
      : setMobilePageIndex((prev) => prev - 1);

  // Go Back
  const goBack = () => {
    mobilePageIndex === 3
      ? setMobilePageIndex(1)
      : setMobilePageIndex((prev) => prev + 1);
    (mobilePageIndex === 2 || mobilePageIndex === 3) && setDateBoxOpen(true);
  };

  const onTypeDestination = (e) => {
    if (e.target.value === "") {
      setCountries(originalData);
      setSports(originalData?.sports);
    } else {
      setTypeValueDestination(e.target.value);
    }
    return _searchCountry(typeValueDestination);
  };

  const onTypeSport = (e) => {
    setTypeValueSport(e.target.value);
    return _searchCategory(typeValueSport);
  };

  // Dates manipulation for calendar of travel dates - Disabled dates

  // Example of Excluded dates
  // const exclude_dates = ["2023-10-08", "2023-10-14"];

  // Example of Work days
  // const work_dates = ["1", "3", "5"];

  useEffect(() => {
    workDay &&
      workDay.forEach((item, index, arr) => (arr[index] = Number(item) - 1));
  }, [workDay]);

  // Full access
  const disabledDateCalendar = (current) => {
    // Work days only
    if (
      calendarData?.specific?.full_access_calender &&
      !calendarData?.specific?.exclude_dates &&
      calendarData?.specific?.work_dates
    ) {
      return workDay.every((date) => date != moment(current).format("d"));
    } else if (
      // Excluded dates only
      calendarData?.specific?.full_access_calender &&
      calendarData?.specific?.exclude_dates &&
      !calendarData?.specific?.work_dates
    ) {
      // Disables Excluded dates - if Available
      return (
        calendarData?.specific?.exclude_dates.find(
          (date) => date === moment(current).format("YYYY-MM-DD")
        ) === moment(current).format("YYYY-MM-DD")
      );
    } else if (
      // Excluded & Work days both
      calendarData?.specific?.full_access_calender &&
      calendarData?.specific?.exclude_dates &&
      calendarData?.specific?.work_dates
    ) {
      return (
        workDay.every((date) => date != moment(current).format("d")) ||
        calendarData?.specific?.exclude_dates.find(
          (date) => date === moment(current).format("YYYY-MM-DD")
        ) === moment(current).format("YYYY-MM-DD")
      );
    } else {
      // Disables dates before today
      return current && current < moment().endOf("day");
    }
  };

  // Restricted Access
  const disabledDateCalendarRestricted = (current) => {
    // Disables dates before today
    current && current < moment().endOf("day");

    // Disables specific dates
    return (
      calendarData?.specific?.valid_dates?.find(
        (date) => date === moment(current).format("YYYY-MM-DD")
      ) != moment(current).format("YYYY-MM-DD")
    );
  };

  const handleSelectFirstSport = (e) => {
    e.stopPropagation();
    _searchCategory(null);

    if (sports.length <= 0) {
      // Select sub sport
      if (subSport.length > 0) {
        setSportId(subSport[0].id);
        setDestVal();
        setSelectedSport(subSport[0].alias);
        setSportVal(subSport[0].title);
        _changeStateInSport({ sport: subSport[0].alias });
        removeDateSelected();
        setSelectedCountry();
        setDestId(0);
      }
    } else {
      // Select sport
      setSelectedSport(sports[0]?.alias);
      setSportId(sports[0]?.id);
      setSportVal(sports[0]?.title);
      _changeStateInSport({ sport: sports[0]?.alias });
    }
  };

  const handleSelectFirstDest = (e) => {
    setShownDate(true);
    e.stopPropagation();
    e.preventDefault();
    setTimeout(() => {
      refdis.current.blur();
      setShownDate(true);
    }, 10);
    setOpenBoxEvent(true);
    setDateBoxOpen(true);

    if (countries?.continents?.length > 0) {
      setDesType("continent");
      setDestId(countries?.continents[0]?.id);
      setSelectedCountry(countries?.continents[0]?.alias);
      setDestVal(countries?.continents[0]?.title);
    } else if (countries?.countries?.length > 0) {
      setDesType("country");
      setDestId(countries?.countries[0]?.id);
      setSelectedCountry(countries?.countries[0]?.alias);
      setDestVal(countries?.countries[0]?.title);
    } else if (countries?.states?.length > 0) {
      setDesType("state");
      setDestId(countries?.states[0]?.id);
      setSelectedCountry(countries?.states[0]?.alias);
      setDestVal(countries?.states[0]?.title);
    } else {
      setDesType("country");
      setSelectedCountry(0);
      setDestId(0);
      setDestVal(t("frontpage.all_des"));
    }
  };

  const removeDateSelected = () => {
    setEndDate("");
    setStartDate("");
    setValue("");
    setShowDate(false);
  };

  const cellRender = (current) => {
    return (
      <div
        className="ant-calendar-date ant-picker-cell-inner"
        title={moment(current).format(
          i18n.language === "en" ? "YYYY/MM/DD" : "YYYY.MM.DD"
        )}
      >
        {current.date()}
      </div>
    );
  };

  return (
    <ConfigProvider locale={i18n.language === "de" ? deLocale : locale}>
      <div className="relative">
        {/* Mobile search section */}
        {isSmScreen ? (
          <div>
            <div className="w-full flex justify-center">
              {props.opened == undefined && (
                <div
                  onClick={() => {
                    setIsOpen(true);
                    props.onOpen(true);
                    // toggleDrawer();
                  }}
                  // -mt-[70px]
                  className={`w-full sm:w-[65%] h-16 sm:h-20 bg-white -mt-[20px] sm:-mt-[70px] rounded-full shadow-xl hover:shadow-2xl hover:scale-110 transition-all duration-500 flex items-center justify-between cursor-pointer ${
                    props.headerMobile && "hidden"
                  }`}
                >
                  <span className="flex flex-col gap-0 items-start justify-center px-8 sm:px-10 text-left">
                    <p className="font-bold text-lg">
                      {mobilePageIndex === 1 && t("frontpage.sport_select")}
                      {mobilePageIndex === 2 &&
                        t("frontpage.destination_select")}
                      {mobilePageIndex === 3 && t("frontpage.travel_date")}
                    </p>
                    <p className="text-base ">
                      {mobilePageIndex === 1 && t("frontpage.sport_select_ph")}
                      {mobilePageIndex === 2 &&
                        t("frontpage.destination_select_ph")}
                      {mobilePageIndex === 3 && t("frontpage.travel_date_ph")}
                    </p>
                  </span>

                  <button className="rounded-full font-medium bg-[#FF9F1C] flex items-center justify-center p-4 sm:p-5 mr-2 shadow-lg">
                    <SearchIcon />
                  </button>
                </div>
              )}

              {/* Content */}
              <div className="relative">
                <Drawer
                  // open={props.opened}
                  open={props.headerMobile ? headerSearch : props.opened}
                  onClose={toggleDrawer}
                  direction="right"
                  size="100%"
                  enableOverlay={false}
                  className="w-full h-full p-5 !overflow-y-auto relative"
                >
                  {/* <img src={CloseIconBlack.src} alt="close" className="w-3" /> */}

                  {/* Top Buttons */}
                  <span className="flex justify-between px-5 border-b pb-3 mb-2 -mt-2">
                    {/* Back */}
                    <p
                      className="font-bold text-lg hover:underline cursor-pointer"
                      onClick={goNext}
                    >
                      {t("frontpage.mobile_search_back")}
                    </p>

                    {/* Skip */}
                    {mobilePageIndex !== 3 ? (
                      <p
                        className="font-bold text-lg hover:underline cursor-pointer"
                        onClick={goBack}
                      >
                        {t("frontpage.mobile_search_skip")}
                      </p>
                    ) : (
                      <p
                        className="font-bold text-lg hover:underline cursor-pointer"
                        // onClick={() => props.onOpen(false)}
                        onClick={() =>
                          props.headerMobile
                            ? setHeaderSearch(false)
                            : props.onOpen(false)
                        }
                      >
                        {t("frontpage.mobile_search_close")}
                      </p>
                    )}
                  </span>

                  {/* Title */}
                  <span
                    className={`flex flex-col px-5 mb-2 relative ${
                      toggle && "pb-3"
                    }`}
                  >
                    <div className="flex items-center justify-between">
                      {/* Sports */}
                      <p className="font-bold text-lg mb-1">
                        {mobilePageIndex === 1 && t("frontpage.sport_select")}

                        {/* Destination */}
                        {mobilePageIndex === 2 &&
                          t("frontpage.destination_select")}

                        {/* Date */}
                        {mobilePageIndex === 3 && t("frontpage.travel_date")}
                      </p>

                      {/* Close Icon */}
                      {/* {mobilePageIndex !== 3 && (
                      <p
                        onClick={() => props.onOpen(false)}
                        className="cursor-pointer"
                      >
                        <IoCloseCircle className="text-2xl text-orange" />
                      </p>
                    )} */}
                    </div>

                    <p
                      className="text-base text-gray-40"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {/* Sports */}
                      {mobilePageIndex === 1 && (
                        <Input
                          placeholder={t("frontpage.mobile_search_sport")}
                          // onSearch={(e) => {
                          //   _searchCategory(e);
                          // }}
                          onChange={(e) => onTypeSport(e)}
                          className="my-1 p-1 -ml-2 rounded-xl border border-gray-10 flex flex-row search-border pl-3 focus:border-none hover:border-gray-10 active:border-gray-10"
                          suffix={
                            <span className="-mt-[6px] pr-2">
                              <SearchOutlined />
                            </span>
                          }
                        />
                      )}

                      {/* Destination */}
                      {mobilePageIndex === 2 && (
                        // t("frontpage.destination_select_ph")}
                        // <Search
                        //   placeholder={t("frontpage.mobile_search_destination")}
                        //   onSearch={(e) => {
                        //     _searchCountry(e);
                        //     setTimeout(() => setCountriesSearch(e), 10000);
                        //   }}
                        //   showAction={["focus", "click"]}
                        //   className="shadow-lg my-1 p-1 -ml-2 rounded-xl"
                        //   onChange={(e) => onTypeDestination(e)}
                        // />
                        <Input
                          placeholder={t("frontpage.mobile_search_destination")}
                          // onSearch={(e) => {
                          //   _searchCategory(e);
                          // }}
                          onChange={(e) => onTypeDestination(e)}
                          showAction={["focus", "click"]}
                          className="my-1 p-1 -ml-2 rounded-xl border border-gray-10 flex flex-row search-border pl-3 focus:border-none hover:border-gray-10 active:border-gray-10"
                          suffix={
                            <span className="-mt-[6px] pr-2">
                              <SearchOutlined />
                            </span>
                          }
                        />
                      )}

                      {/* Date */}
                      {mobilePageIndex === 3 && toggle && (
                        <>
                          {value.length >= 1 ? (
                            <span className="!line-clamp-1">
                              {value.map(
                                (val, i) =>
                                  `${i >= 1 ? " - " : ""} ${moment(val)
                                    .locale(i18n.language)
                                    .format("MMMM YYYY")}`
                              )}
                            </span>
                          ) : (
                            t("frontpage.travel_date_ph")
                          )}
                        </>
                      )}
                    </p>
                  </span>

                  {/* List Items */}

                  {/* Sports Section - Page 1 */}
                  {mobilePageIndex === 1 && (
                    <div className="h-full overflow-scroll overflow-x-hidden scroll -mt-2">
                      <ul className="mt-5 px-5 flex flex-col gap-5 w-full h-full">
                        {sports?.length > 0 && (
                          <p className="text-base font-bold text-[#9d9ca0]">
                            {t("home_search_popular_sports")}
                          </p>
                        )}
                        {/* All sports - static */}
                        <li
                          value={0}
                          className="text-base cursor-pointer hover:scale-95 transition-all duration-300 hover:text-orange"
                          onClick={(e) => {
                            handleClick(e);
                            _changeStateInSport({ sport: "all" });
                            setSelectedSport("all");
                            setSportId(0);
                          }}
                        >
                          <span className="flex gap-2 font-bold">
                            {t("frontpage.all_sports")}
                          </span>
                        </li>

                        {sports?.map((i, index) => {
                          return (
                            <li
                              value={i.alias}
                              key={index}
                              className="text-base cursor-pointer hover:scale-95 transition-all duration-300 hover:text-orange"
                              onClick={(e) => {
                                handleClick(e);
                                _changeStateInSport({ sport: i.alias });
                                setSelectedSport(i.alias);
                                setSportId(i.id);
                              }}
                            >
                              <span className="flex gap-2 font-bold">
                                {i.title}
                              </span>
                            </li>
                          );
                        })}

                        {/* {subSport?.length > 0 && showSubSport && ( */}
                        {subSport?.length > 0 && (
                          <>
                            <p className="text-base font-bold text-[#9d9ca0] mt-5">
                              {t("home_search_popular_subsports")}
                            </p>
                            {subSport?.map((i, index) => {
                              return (
                                <li
                                  value={i.alias}
                                  key={index}
                                  className="text-base cursor-pointer hover:scale-95 transition-all duration-300 hover:text-orange"
                                  onClick={(e) => {
                                    handleClick(e);
                                    _changeStateInSport({ sport: i.alias });
                                    setSelectedSport(i.alias);
                                    setSportId(i.id);
                                  }}
                                >
                                  <span className="flex gap-2 font-bold">
                                    {i.title}
                                  </span>
                                </li>
                              );
                            })}
                          </>
                        )}

                        {(sports?.length === 0 || isSearching) && (
                          <div className="w-full h-full flex justify-center items-center">
                            <div className="w-[100px] h-[300px] bg-transparent flex justify-center items-center">
                              {/* Top */}
                              <div className="w-full flex items-center justify-between" />

                              {/* Loader */}
                              <div className="w-full h-full flex justify-center items-center !-mt-[150px]">
                                <div className="lds-roller">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </ul>
                    </div>
                  )}

                  {/* Destination Section - Page 2 */}
                  {mobilePageIndex === 2 && (
                    <ul className="mt-5 px-5 flex flex-col gap-5 w-full h-full overflow-scroll overflow-x-hidden scroll">
                      {/* All Destination - static */}
                      <li
                        value={0}
                        className="text-base cursor-pointer hover:scale-95 transition-all duration-300 hover:text-orange"
                        onClick={(e) => {
                          handleClickDestination(e);
                          setSelectedCountry(0);
                          setDestId(0);
                        }}
                      >
                        <span className="flex gap-2 font-bold">
                          {t("frontpage.all_des")}
                        </span>
                      </li>

                      {countries?.continents?.length > 0 ||
                      countries?.countries?.length > 0 ||
                      countries?.states?.length > 0 ||
                      countries !== undefined ? (
                        <>
                          {/* Continents */}
                          {countries?.continents?.length > 0 && (
                            <>
                              <p className="text-base font-bold text-[#9d9ca0]">
                                {t("home_search_popular_continents")}
                              </p>
                              {countries?.continents?.map((i, index) => {
                                return (
                                  <li
                                    key={index}
                                    value={i?.alias}
                                    className="text-base cursor-pointer hover:scale-95 transition-all duration-300 hover:text-orange"
                                    onClick={(e) => {
                                      handleClickDestination(e);
                                      setSelectedCountry(i?.alias);
                                      setDestId(i.id);
                                      setDateBoxOpen(true);
                                      setDesType("continent");
                                    }}
                                  >
                                    <span className="flex items-center gap-1 font-bold">
                                      {i.title}
                                    </span>
                                  </li>
                                );
                              })}
                            </>
                          )}

                          {/* Countries */}
                          {countries?.countries?.length > 0 && (
                            <>
                              <p className="text-base font-bold text-[#9d9ca0]">
                                {t("home_search_popular_countries")}
                              </p>
                              {countries?.countries?.map((i, index) => {
                                return (
                                  <li
                                    key={index}
                                    value={i?.alias}
                                    className="text-base cursor-pointer hover:scale-95 transition-all duration-300 hover:text-orange"
                                    onClick={(e) => {
                                      handleClickDestination(e);
                                      setSelectedCountry(i?.alias);
                                      setDestId(i.id);
                                      setDateBoxOpen(true);
                                      setDesType("country");
                                    }}
                                  >
                                    <span className="flex items-center gap-1 font-bold">
                                      {i.title}
                                    </span>
                                  </li>
                                );
                              })}
                            </>
                          )}

                          {/* States */}
                          {countries?.states?.length > 0 && (
                            <>
                              <p className="text-base font-bold text-[#9d9ca0]">
                                {t("home_search_popular_states")}
                              </p>
                              {countries?.states?.map((i, index) => {
                                return (
                                  <li
                                    key={index}
                                    value={i?.alias}
                                    className="text-base cursor-pointer hover:scale-95 transition-all duration-300 hover:text-orange"
                                    onClick={(e) => {
                                      handleClickDestination(e);
                                      setSelectedCountry(i?.alias);
                                      setDestId(i.id);
                                      setDateBoxOpen(true);
                                      setDesType("state");
                                    }}
                                  >
                                    <span className="flex items-center gap-1 font-bold capitalize">
                                      {i.title}
                                    </span>
                                  </li>
                                );
                              })}
                            </>
                          )}
                        </>
                      ) : (
                        <div className="w-full h-full flex items-center justify-center">
                          <div className="w-[100px] h-[100px] bg-transparent flex justify-center items-center">
                            {/* Top */}
                            <div className="w-full flex items-center justify-between" />

                            {/* Loader */}
                            <div className="w-full h-full flex justify-center items-center !-mt-[150px]">
                              <div className="lds-roller">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </ul>
                  )}

                  {/* Date Section - Page 3 */}
                  {mobilePageIndex === 3 && (
                    <div
                      className="flex flex-col justify-between"
                      onClick={() => setShowMobileCalendar((prev) => !prev)}
                    >
                      <div className="w-full px-1 relative">
                        <div className="mb-0 flex w-full items-center justify-center">
                          <TailwindToggle setValue={setToggle} value={toggle} />
                        </div>
                        {calendarData == undefined ? (
                          <div className="w-full h-[400px] flex items-center justify-center">
                            <Placeholder />
                          </div>
                        ) : (
                          <>
                            {toggle ? (
                              <>
                                {calendarData?.flexible?.full_access ? (
                                  // Show 12 monthes from now - All
                                  <div
                                    onClick={(e) => e.stopPropagation()}
                                    className="flex w-full justify-center items-center mt-5 overflow-auto h-[400px] sm:h-fit sm:pt-8"
                                  >
                                    <Checkbox.Group
                                      onChange={onChange4}
                                      value={value}
                                      optionType="button"
                                      buttonStyle="solid"
                                      className="grid grid-cols-2 gap-2 -mt-5 w-full sm:w-[85%] pr-2 sm:pr-0"
                                    >
                                      {_.range(0, 12).map((m) => {
                                        return (
                                          <Checkbox
                                            className={
                                              "flex justify-between flex-row-reverse items-center border py-1 sm:py-3 px-3 mt-2 rounded-lg shadow-sm w-full first:!ml-2"
                                            }
                                            onClick={(e) => e.stopPropagation()}
                                            value={moment()
                                              .add(m, "M")
                                              .format("YYYY-MM")}
                                          >
                                            <div
                                              className={
                                                "flex text-center justify-center"
                                              }
                                            >
                                              {moment()
                                                .add(m, "M")
                                                .format("MMMM")}
                                            </div>
                                            <p
                                              className={
                                                "flex font-light text-center justify-center text-xs"
                                              }
                                            >
                                              {moment()
                                                .add(m, "M")
                                                .format("YYYY")}
                                            </p>
                                          </Checkbox>
                                        );
                                      })}
                                    </Checkbox.Group>
                                  </div>
                                ) : (
                                  <>
                                    {calendarData?.flexible?.valid_month
                                      .length == 0 ? (
                                      <div className="w-full h-[400px] flex items-center justify-center mt-10">
                                        <p className="text-base font-medium">
                                          No dates available!
                                        </p>
                                      </div>
                                    ) : (
                                      // Only show available monthes
                                      <div
                                        onClick={(e) => e.stopPropagation()}
                                        className="flex w-full justify-center items-center mt-5 overflow-auto h-[400px] sm:h-fit sm:pt-8"
                                      >
                                        <Checkbox.Group
                                          onChange={onChange4}
                                          value={value}
                                          optionType="button"
                                          buttonStyle="solid"
                                          className="grid grid-cols-2 gap-2 -mt-5 w-full sm:w-[85%] pr-2 sm:pr-0"
                                        >
                                          {_.range(0, 12).map((m) => {
                                            return (
                                              <Checkbox
                                                className={`flex justify-between flex-row-reverse items-center border py-1 sm:py-3 px-3 mt-2 rounded-lg shadow-sm w-full first:!ml-2 ${
                                                  calendarData?.flexible?.valid_month.find(
                                                    (mo) =>
                                                      moment(mo).format(
                                                        "YYYY-MM"
                                                      ) ===
                                                      moment()
                                                        .add(m, "M")
                                                        .format("YYYY-MM")
                                                  )
                                                    ? "cursor-pointer hover:bg-gray-30"
                                                    : "!bg-gray-70 !cursor-not-allowed"
                                                }`}
                                                onClick={(e) =>
                                                  e.stopPropagation()
                                                }
                                                value={moment()
                                                  .add(m, "M")
                                                  .format("YYYY-MM")}
                                                disabled={
                                                  calendarData?.flexible?.valid_month.find(
                                                    (mo) =>
                                                      moment(mo).format(
                                                        "YYYY-MM"
                                                      ) ===
                                                      moment()
                                                        .add(m, "M")
                                                        .format("YYYY-MM")
                                                  )
                                                    ? false
                                                    : true
                                                }
                                              >
                                                <div
                                                  className={
                                                    "flex text-center justify-center"
                                                  }
                                                >
                                                  {moment()
                                                    .add(m, "M")
                                                    .format("MMMM")}
                                                </div>
                                                <p
                                                  className={
                                                    "flex font-light text-center justify-center text-xs"
                                                  }
                                                >
                                                  {moment()
                                                    .add(m, "M")
                                                    .format("YYYY")}
                                                </p>
                                              </Checkbox>
                                            );
                                          })}
                                        </Checkbox.Group>
                                      </div>
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                {calendarData?.specific
                                  ?.full_access_calender ? (
                                  // Open access to all dates
                                  <div
                                    // onClick={(e) => e.stopPropagation()}
                                    className="flex w-full relative items-center z-50 justify-center main-filter-date-picker mt-2"
                                  >
                                    <span className="w-[90%] min-h-[59vh] md:min-h-[70vh]">
                                      <RangePicker
                                        value={showDate}
                                        disabledDate={disabledDateCalendar}
                                        popupClassName={
                                          "borderLess !ml-5 md:!ml-0 !bottom-0"
                                        }
                                        onChange={(e) => {
                                          e &&
                                            setStartDate(
                                              moment(e[0]?._d).format(
                                                "YYYY/MM/DD"
                                              )
                                            );
                                          e &&
                                            setEndDate(
                                              moment(e[1]?._d).format(
                                                "YYYY/MM/DD"
                                              )
                                            );
                                          setShowDate(e);
                                        }}
                                        bordered={false}
                                        className="text-center left-[3%] md:left-[30%]"
                                        placement="bottomRight"
                                        size="large"
                                        monthCellRender={(date) =>
                                          date.format("MMMM")
                                        }
                                        dateRender={cellRender}
                                        panelRender={(panel) => (
                                          <div className="w-full relative">
                                            <div className="w-full z-[9999] offer_datepicker_2">
                                              {panel}
                                            </div>
                                            <span className="!absolute bottom-0 w-full h-[60px] !bg-white !z-[999999]" />
                                          </div>
                                        )}
                                        format={DateHelper.getFormat()}
                                      />
                                    </span>
                                  </div>
                                ) : (
                                  <>
                                    {calendarData?.specific?.valid_dates
                                      ?.length == 0 ? (
                                      <div className="w-full h-[300px] flex items-center justify-center mt-10">
                                        <p className="text-base font-medium">
                                          No dates available!
                                        </p>
                                      </div>
                                    ) : (
                                      // Resticted access - Only available dates
                                      <div className="flex w-full relative items-center z-50 justify-center main-filter-date-picker mt-2">
                                        <span className="w-[90%] min-h-[59vh] md:min-h-[70vh]">
                                          <RangePicker
                                            monthCellRender={(date) =>
                                              date.format("MMMM")
                                            }
                                            value={showDate}
                                            disabledDate={
                                              disabledDateCalendarRestricted
                                            }
                                            popupClassName={
                                              "borderLess !ml-5 md:!ml-0 offer_datepicker_2"
                                            }
                                            onChange={(e) => {
                                              e &&
                                                setStartDate(
                                                  moment(e[0]?._d).format(
                                                    "YYYY/MM/DD"
                                                  )
                                                );
                                              e &&
                                                setEndDate(
                                                  moment(e[1]?._d).format(
                                                    "YYYY/MM/DD"
                                                  )
                                                );
                                              setShowDate(e);
                                            }}
                                            bordered={false}
                                            className="text-center left-[3%] md:left-[30%]"
                                            placement="bottomRight"
                                            size="large"
                                            dateRender={cellRender}
                                            panelRender={(panel) => (
                                              <div className="w-full relative">
                                                <div className="w-full z-[9999]">
                                                  {panel}
                                                </div>
                                                <span className="!absolute bottom-0 w-full h-[60px] !bg-white !z-[999999]" />
                                              </div>
                                            )}
                                            format={DateHelper.getFormat()}
                                          />
                                        </span>
                                      </div>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                      {/* Search button */}
                      <div className="flex justify-center items-center py-5 ">
                        <button
                          className="bg-orange py-2 px-6 text-white font-bold w-[320px] rounded-full text-lg  sm:w-[76%]"
                          onClick={(e) => {
                            e.stopPropagation();
                            const endOF = [];
                            if (selectedSport != undefined) {
                              endOF.push(`sport=${selectedSport}`);
                            }

                            if (
                              selectedCountry != undefined &&
                              selectedCountry !== 0
                            ) {
                              const findKey = _.find(countries, {
                                alias: selectedCountry,
                              });
                              if (findKey != undefined) {
                                if (selectedCountry?.includes("&")) {
                                  endOF.push(
                                    `${
                                      findKey?.key
                                    }[]=${selectedCountry?.replace("&", "%26")}`
                                  );
                                } else {
                                  endOF.push(
                                    `${findKey?.key}[]=${selectedCountry}`
                                  );
                                }
                              } else {
                                if (selectedCountry?.includes("&")) {
                                  endOF.push(
                                    `${desType}[]=${selectedCountry?.replace(
                                      "&",
                                      "%26"
                                    )}`
                                  );
                                } else {
                                  endOF.push(`${desType}[]=${selectedCountry}`);
                                }
                              }
                            }
                            if (
                              startDate &&
                              startDate != undefined &&
                              startDate != ""
                            ) {
                              endOF.push(
                                `from=${moment(startDate, "YYYY/MM/DD").format(
                                  "DD-MM-YYYY"
                                )}&to=${moment(endDate, "YYYY/MM/DD").format(
                                  "DD-MM-YYYY"
                                )}`
                              );
                            }
                            if (
                              !startDate &&
                              value != undefined &&
                              value != ""
                            ) {
                              let text = [];
                              if (typeof value == "object") {
                                value.map((dd1) => {
                                  // text.push("month[]=" + dd1);
                                  text.push("month=" + dd1);
                                });
                              }
                              endOF.push(text.join("&"));
                            }
                            const final = endOF.join("&");
                            location.href = `/s/moverii?${final}`;
                          }}
                        >
                          {t("frontpage.search_mobile")}
                        </button>
                      </div>
                    </div>
                  )}
                </Drawer>
              </div>
            </div>
          </div>
        ) : (
          // Desktop Search
          <main
            className="flex min-h-[300px] w-full flex-col items-center justify-start lg:px-0 px-1 "
            onClick={() => {
              props.onOpen(false);
            }}
          >
            <div
              focused="true"
              tabIndex="0"
              onBlur={() => {
                setShownDate(false);
                if (shownDate) {
                }
              }}
            >
              <section className="relative saearchbar-top form-no-border flex flex-col md:mt-5 mt-10 lg:flex-row items-center justify-center rounded-[1rem] lg:rounded-full bg-gray-100 z-50">
                {/* Sports */}
                <article
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={`flex w-72 input-main-search flex-col items-start justify-center rounded-[1.25rem] lg:rounded-l-[35px] pb-1 pt-3 lg:py-1 pr-8 pl-3`}
                >
                  <p
                    className={`m-0 font-medium !-mb-3 mt-2 flex items-center justify-center gap-x-2 pl-5`}
                  >
                    {t("frontpage.sport_select")}
                  </p>
                  <Select
                    showSearch
                    value={sportVal}
                    placeholder={t("frontpage.sport_select_ph")}
                    onChange={handleChange}
                    allowClear
                    className={`w-full placeholder-gray-900 !pt-1 text-[14px] sport_select scroll [&_.ant-select-selection-item]:!ml-4`}
                    filterOption={false}
                    bordered={false}
                    popupClassName="dropDownSearch md:!w-[300px] !font-bold scroll md:!h-[400px]"
                    listHeight={400}
                    setSearchPosition
                    onSelect={(e) => {
                      handleClick(e);
                    }}
                    onClick={(e) => {
                      setOpenBoxEvent(true);
                      setShadow1(true);
                      setShownDate(false);
                    }}
                    onBlur={() => {
                      setShadow1(false);
                    }}
                    onKeyDown={(e) =>
                      (e.key === "Enter" || e.key === "Tab") &&
                      handleSelectFirstSport(e)
                    }
                    onSearch={(e) => {
                      _searchCategory(e);
                    }}
                    onClear={() => {
                      setCountries([]);
                      setCountries(originalData);
                      setSports(originalData?.sports);
                      setSportVal();
                      setDestVal();
                      removeDateSelected();
                      setSelectedSport();
                      setSelectedCountry();
                      setDestId(0);
                      setSportId(0);
                    }}
                    showArrow={false}
                    virtual={false}
                  >
                    <div className="w-full h-full overflow-scroll scroll">
                      {/* // All Sports - Static */}
                      <li
                        value={0}
                        className={"!pl-5 py-1.5 hover:bg-gray-20 mb-2"}
                        onClick={() => {
                          setSelectedSport(0);
                          setSportId(0);
                          setSportVal(t("frontpage.all_sports"));
                          setDestVal();
                          _changeStateInSport({ sport: 0 });
                          removeDateSelected();
                          setSelectedCountry();
                          setDestId(0);
                        }}
                      >
                        <span
                          className="font-bold text-sm !-ml-3.5"
                          onClick={() => {
                            setSportVal(t("frontpage.all_sports"));
                          }}
                        >
                          {t("frontpage.all_sports")}
                        </span>
                      </li>
                      <p className="text-sm font-normal text-gray-50 !-ml-3.5 mb-2 !pl-5">
                        {t("frontpage.sports_dropdown_label")}
                      </p>

                      {sports?.length > 0 || !isSearching ? (
                        <ul>
                          {sports?.map((i, index) => {
                            return (
                              <li
                                key={index}
                                className={"py-1.5 hover:bg-gray-20 !pl-2"}
                                onClick={() => {
                                  setSelectedSport(i.alias);
                                  setSportId(i.id);
                                  setSportVal(i.title);
                                  setDestVal();
                                  _changeStateInSport({ sport: i.alias });
                                  removeDateSelected();
                                  setSelectedCountry();
                                  setDestId(0);
                                }}
                              >
                                <span className="font-bold">{i.title}</span>
                              </li>
                            );
                          })}

                          {/* SubSports */}
                          {subSport?.length > 0 &&
                            subSport?.map((i, index) => {
                              return (
                                <li
                                  // value={i.alias}
                                  key={index}
                                  className={"py-1.5 hover:bg-gray-20 !pl-2"}
                                  onClick={() => {
                                    setSportId(i.id);
                                    setDestVal();
                                    setSelectedSport(i.alias);
                                    setSportVal(i.title);
                                    _changeStateInSport({ sport: i.alias });
                                    removeDateSelected();
                                    setSelectedCountry();
                                    setDestId(0);
                                  }}
                                >
                                  <span className="font-bold">{i.title}</span>
                                </li>
                              );
                            })}
                        </ul>
                      ) : (
                        <div className="w-full h-full flex justify-center items-center">
                          <div className="w-[100px] h-[300px] bg-transparent flex justify-center items-center">
                            {/* Top */}
                            <div className="w-full flex items-center justify-between" />

                            {/* Loader */}
                            <div className="w-full h-full flex justify-center items-center !-mt-20">
                              <div className="lds-roller">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </Select>
                </article>
                <Divider
                  type="vertical"
                  className="h-full lg:h-[50px] bg-gray-400"
                />

                {/* Destination */}
                <article
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  className={`flex w-72  flex-col items-start justify-center rounded-[1.25rem] bg-gray-100 pb-1 pt-3 lg:py-1`}
                >
                  <p className="m-0 !pr-8 lg:pl-1 font-medium !-mb-3 mt-2 flex items-center justify-center gap-x-2 ">
                    {t("frontpage.destination_select")}
                  </p>
                  <div className=" w-full">
                    <Select
                      value={destVal}
                      showSearch
                      placeholder={t("frontpage.destination_select_ph")}
                      onChange={destinationHandler}
                      allowClear
                      ref={refdis}
                      bordered={false}
                      filterOption={false}
                      openOnFocus
                      className="select-dist-antd  select-search w-full border-0 pt-0 lg:!-ml-8  home-select-2 text-[14px] dest-class scroll "
                      popupClassName="dropDownSearch md:!w-[340px] scroll md:!h-[400px] !bg-white"
                      listHeight={400}
                      onClick={() => {
                        setShadow2(true);
                        setOpenBoxEvent(true);
                        // props.setSearchPosition(true);
                        setShownDate(false);
                      }}
                      showAction={["focus", "click"]}
                      onSearch={(e) => {
                        _searchCountry(e);
                        // setTimeout(() => setCountriesSearch(e), 1000);
                      }}
                      onBlur={() => {
                        setShadow2(false);
                        if (!shownDate) {
                          // props.setSearchPosition(false);
                        }
                      }}
                      showArrow={false}
                      virtual={false}
                      onClear={() => {
                        setCountries(originalData);
                        setSports(originalData?.sports);
                        setDestVal();
                        removeDateSelected();
                        setSelectedCountry();
                        setDestId(0);
                      }}
                      onKeyDown={(e) =>
                        (e.key === "Enter" || e.key === "Tab") &&
                        handleSelectFirstDest(e)
                      }
                    >
                      <div className="pl-3 w-full h-full overflow-scroll scroll">
                        {/* // All Destinations - Static */}
                        <li
                          value={0}
                          className={"!pl-5 py-1.5 hover:bg-gray-20 mb-2"}
                          onClick={() => {
                            setSelectedCountry(0);
                            setDestId(0);
                            setDestVal(t("frontpage.all_des"));
                            removeDateSelected();
                          }}
                        >
                          <span className="font-bold text-sm !-ml-3.5">
                            {t("frontpage.all_des")}
                          </span>
                        </li>

                        {countries?.continents?.length > 0 ||
                        countries?.countries?.length > 0 ||
                        countries?.states?.length > 0 ||
                        countries != undefined ? (
                          <ul>
                            {/* Continents */}
                            {countries?.continents?.length > 0 && (
                              <>
                                <p className="text-sm font-normal text-gray-50 !-ml-3.5 mb-2 !pl-5">
                                  {t("home_search_popular_continents")}
                                </p>
                                {countries?.continents?.map((i, index) => {
                                  return (
                                    <li
                                      key={index}
                                      // value={i?.alias}
                                      className={
                                        "py-1.5 hover:bg-gray-20 !pl-2"
                                      }
                                      onClick={() => {
                                        setDestId(i.id);
                                        setDesType("continent");
                                        setSelectedCountry(i.alias);
                                        setDestVal(i.title);
                                        removeDateSelected();
                                      }}
                                    >
                                      <span className="font-bold capitalize">
                                        {i.title}
                                      </span>
                                    </li>
                                  );
                                })}
                              </>
                            )}

                            {/* Countries */}
                            {countries?.countries?.length > 0 && (
                              <>
                                <p className="text-sm font-normal text-gray-50 !-ml-3.5 mb-2 mt-3 !pl-5">
                                  {t("home_search_popular_countries")}
                                </p>
                                {countries?.countries?.map((i, index) => (
                                  <li
                                    key={index}
                                    // value={i?.country}
                                    className={"py-1.5 hover:bg-gray-20 !pl-2"}
                                    onClick={() => {
                                      setDestId(i.id);
                                      setDesType("country");
                                      setSelectedCountry(i.alias);
                                      setDestVal(i.title);
                                      removeDateSelected();
                                    }}
                                  >
                                    <span className="font-bold capitalize">
                                      {i.title}
                                    </span>
                                  </li>
                                ))}
                              </>
                            )}

                            {/* States */}
                            {countries?.states?.length > 0 && (
                              <>
                                <p className="text-sm font-normal text-gray-50 !-ml-3.5 mb-2 mt-3 !pl-5">
                                  {t("home_search_popular_states")}
                                </p>
                                {countries?.states?.map((i, index) => {
                                  return (
                                    <li
                                      key={index}
                                      // value={i?.city}
                                      className={
                                        "py-1.5 hover:bg-gray-20 !pl-2"
                                      }
                                      onClick={() => {
                                        setDestId(i.id);
                                        setDesType("state");
                                        setSelectedCountry(i.alias);
                                        setDestVal(i.title);
                                        removeDateSelected();
                                      }}
                                    >
                                      <span className="font-bold capitalize">
                                        {i.title}
                                      </span>
                                    </li>
                                  );
                                })}
                              </>
                            )}
                          </ul>
                        ) : (
                          <div className="w-full h-full flex justify-center items-center">
                            <div className="w-[100px] h-[300px] bg-transparent flex justify-center items-center">
                              {/* Top */}
                              <div className="w-full flex items-center justify-between" />

                              {/* Loader */}
                              <div className="w-full h-full flex justify-center items-center !-mt-14">
                                <div className="lds-roller">
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                  <div></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </Select>
                  </div>
                </article>
                <Divider
                  type="vertical"
                  className="h-full lg:h-[50px] bg-gray-400"
                />

                {/* Date picker */}
                <div
                  className={`flex flex-col md:flex-row items-center justify-center h-full overflow-hidden rounded-[1.25rem] pr-2.5`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenBoxEvent(true);
                    setShownDate((prev) => !prev);
                    setDateBoxOpen((prev) => !prev);
                    // props.setSearchPosition(true);
                  }}
                >
                  <article
                    className={`flex w-60 cursor-pointer flex-col items-start justify-center py-2 pr-4 pl-0`}
                  >
                    <div className="flex !justify-between items-center w-full">
                      <div className="flex flex-col items-start">
                        <p className="m-0 flex  font-medium items-center justify-center gap-x-2 pl-1 mb-[2px]">
                          {/*<TravelIcon/>*/} {t("frontpage.travel_date")}
                        </p>
                        <p className="m-0 text-start font-base text-[#2B2D42] pl-1 text-[14px]">
                          {value.length >= 1 ? (
                            <span className="!line-clamp-1">
                              {value.map(
                                (val, i) =>
                                  `${i >= 1 ? " - " : ""} ${moment(val)
                                    .locale(i18n.language)
                                    .format("MMMM YYYY")}`
                              )}
                            </span>
                          ) : (
                            <>
                              {startDate && endDate ? (
                                <>
                                  {DateHelper.dateFormat(startDate)}
                                  {" - "}
                                  {DateHelper.dateFormat(endDate)}{" "}
                                </>
                              ) : (
                                <> {t("frontpage.travel_date_ph")}</>
                              )}
                            </>
                          )}
                        </p>
                      </div>

                      {((startDate && endDate) || value.length >= 1) && (
                        <div
                          className="flex items-end justify-end -mb-5"
                          onClick={() => {
                            removeDateSelected();
                          }}
                        >
                          <IoCloseCircleSharp className=" text-gray-70 hover:text-gray-500" />
                        </div>
                      )}
                    </div>
                  </article>
                  <button
                    className="rounded-full md:mb-0 mb-10 w-10/12 md:w-14 font-medium  bg-[#FF9F1C] p-4 -mr-1"
                    onClick={(e) => {
                      e.stopPropagation();
                      enableScroll();

                      const endOF = [];
                      if (selectedSport != undefined) {
                        endOF.push(`sport[]=${selectedSport}`);
                      }
                      if (
                        selectedCountry != undefined &&
                        selectedCountry !== 0
                      ) {
                        const findKey = _.find(countries, {
                          alias: selectedCountry,
                        });
                        if (findKey != undefined) {
                          if (selectedCountry?.includes("&")) {
                            endOF.push(
                              `${findKey?.key}[]=${selectedCountry?.replace(
                                "&",
                                "%26"
                              )}`
                            );
                          } else {
                            endOF.push(`${findKey?.key}[]=${selectedCountry}`);
                          }
                        } else {
                          if (selectedCountry?.includes("&")) {
                            endOF.push(
                              `${desType}[]=${selectedCountry?.replace(
                                "&",
                                "%26"
                              )}`
                            );
                          } else {
                            endOF.push(`${desType}[]=${selectedCountry}`);
                          }
                        }
                      }
                      if (
                        startDate &&
                        startDate != undefined &&
                        startDate != ""
                      ) {
                        endOF.push(
                          `from=${moment(startDate, "YYYY/MM/DD").format(
                            "DD-MM-YYYY"
                          )}&to=${moment(endDate, "YYYY/MM/DD").format(
                            "DD-MM-YYYY"
                          )}`
                        );
                      }
                      if (!startDate && value != undefined && value != "") {
                        let text = [];
                        if (typeof value == "object") {
                          value.map((dd1) => {
                            text.push("month[]=" + dd1);
                          });
                        }
                        endOF.push(text.join("&"));
                      }
                      const final = endOF.join("&");
                      location.href = `/s/moverii?${final}`;
                    }}
                  >
                    <SearchIcon />
                  </button>
                </div>
              </section>

              {/* Date Container */}
              {/* New Version */}
              {shownDate && (
                <section
                  className="mt-4 relative z-50 rounded-[1.25rem] bg-white pb-2 md:pb-16 md:pt-5 radio-main-body"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="mb-0 flex w-full items-center justify-center">
                    <TailwindToggle setValue={setToggle} value={toggle} />
                  </div>
                  {calendarData == undefined ? (
                    <div className="w-full h-full flex items-center justify-center">
                      <Placeholder />
                    </div>
                  ) : (
                    <>
                      {toggle ? (
                        <>
                          {calendarData?.flexible?.full_access ? (
                            // Show 12 monthes from now - All
                            <div
                              onClick={(e) => e.stopPropagation()}
                              className={"mt-8"}
                            >
                              <Checkbox.Group
                                onChange={onChange4}
                                value={value}
                                optionType="button"
                                buttonStyle="solid"
                              >
                                {_.range(0, 12).map((m) => {
                                  return (
                                    <Checkbox
                                      className={
                                        "flex flex-col justify-center items-center hover:bg-gray-30"
                                      }
                                      onClick={(e) => e.stopPropagation()}
                                      value={moment()
                                        .add(m, "M")
                                        .format("YYYY-MM")}
                                    >
                                      <div
                                        className={
                                          "flex text-center justify-center"
                                        }
                                      >
                                        {moment().add(m, "M").format("MMMM")}
                                      </div>
                                      <div
                                        className={
                                          "flex font-light text-center justify-center text-xs"
                                        }
                                      >
                                        {moment().add(m, "M").format("YYYY")}
                                      </div>
                                    </Checkbox>
                                  );
                                })}
                              </Checkbox.Group>
                            </div>
                          ) : (
                            <>
                              {calendarData?.flexible?.valid_month.length ==
                              0 ? (
                                <div className="w-full h-full flex items-center justify-center mt-10">
                                  No dates here
                                </div>
                              ) : (
                                // Only show available monthes
                                <div
                                  onClick={(e) => e.stopPropagation()}
                                  className={"mt-8"}
                                >
                                  <Checkbox.Group
                                    onChange={onChange4}
                                    value={value}
                                    optionType="button"
                                    buttonStyle="solid"
                                  >
                                    {_.range(0, 12).map((m) => {
                                      return (
                                        <Checkbox
                                          className={`flex flex-col justify-center items-center ${
                                            calendarData?.flexible?.valid_month.find(
                                              (mo) =>
                                                moment(mo).format("YYYY-MM") ===
                                                moment()
                                                  .add(m, "M")
                                                  .format("YYYY-MM")
                                            )
                                              ? "cursor-pointer hover:bg-gray-30"
                                              : "!bg-gray-70 !cursor-not-allowed"
                                          }`}
                                          onClick={(e) => e.stopPropagation()}
                                          value={moment()
                                            .add(m, "M")
                                            .format("YYYY-MM")}
                                          disabled={
                                            calendarData?.flexible?.valid_month.find(
                                              (mo) =>
                                                moment(mo).format("YYYY-MM") ===
                                                moment()
                                                  .add(m, "M")
                                                  .format("YYYY-MM")
                                            )
                                              ? false
                                              : true
                                          }
                                        >
                                          <div
                                            className={
                                              "flex text-center justify-center"
                                            }
                                          >
                                            {moment()
                                              .add(m, "M")
                                              .format("MMMM")}
                                          </div>
                                          <div
                                            className={
                                              "flex font-light text-center justify-center text-xs"
                                            }
                                          >
                                            {moment()
                                              .add(m, "M")
                                              .format("YYYY")}
                                          </div>
                                        </Checkbox>
                                      );
                                    })}
                                  </Checkbox.Group>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {calendarData?.specific?.full_access_calender ? (
                            // Open access to all dates
                            <div
                              onClick={(e) => e.stopPropagation()}
                              className="flex w-full !relative items-center z-50 justify-center main-filter-date-picker h-80 lg:h-[20rem]"
                            >
                              <RangePicker
                                open={true}
                                format={DateHelper.getFormat()}
                                size="large"
                                value={showDate}
                                style={{ width: "0", visibility: "hidden" }}
                                // disabledDate={disabledDate}
                                disabledDate={disabledDateCalendar}
                                dropdownClassName={
                                  "md:ml-14 lg:ml-[60px] borderLess !border-none lg:!mt-6 header_calendar lg:absolute lg:!top-[165px]"
                                }
                                onChange={(e) => {
                                  setEndDate(
                                    moment(e[1]._d).format("YYYY/MM/DD")
                                  );
                                  setStartDate(
                                    moment(e[0]._d).format("YYYY/MM/DD")
                                  );
                                  setShowDate(e);
                                }}
                                bordered={false}
                                placement="bottomRight"
                                className="absolute -bottom-12 !border-none"
                                monthCellRender={(date) => date.format("MMMM")}
                                dateRender={cellRender}
                                panelRender={(panel) => (
                                  <div className="w-full relative">
                                    <div className="w-full z-[9999]">
                                      {panel}
                                    </div>
                                    <span className="!absolute bottom-0 w-full h-[60px] !bg-white !z-[999999]" />
                                  </div>
                                )}
                              />
                            </div>
                          ) : (
                            <>
                              {calendarData?.specific?.valid_dates?.length ==
                              0 ? (
                                <div className="w-full h-full flex items-center justify-center mt-10">
                                  No Date available
                                </div>
                              ) : (
                                //  {/* Resticted access - Only available dates */}
                                <div
                                  onClick={(e) => e.stopPropagation()}
                                  className="flex w-full relative items-center z-50 justify-center main-filter-date-picker h-80 lg:h-[20rem]"
                                >
                                  <RangePicker
                                    open={true}
                                    size="large"
                                    value={showDate}
                                    style={{ width: "0", visibility: "hidden" }}
                                    // disabledDate={disabledDate}
                                    disabledDate={
                                      disabledDateCalendarRestricted
                                    }
                                    dropdownClassName={
                                      "md:ml-14 lg:ml-[60px] borderLess !border-none lg:!mt-6 header_calendar lg:absolute lg:!top-[165px]"
                                    }
                                    onChange={(e) => {
                                      setEndDate(
                                        moment(e[1]._d).format("YYYY/MM/DD")
                                      );
                                      setStartDate(
                                        moment(e[0]._d).format("YYYY/MM/DD")
                                      );
                                      setShowDate(e);
                                    }}
                                    bordered={false}
                                    placement="bottomRight"
                                    className="absolute -bottom-12 !border-none"
                                    monthCellRender={(date) =>
                                      date.format("MMMM")
                                    }
                                    dateRender={cellRender}
                                    panelRender={(panel) => (
                                      <div className="w-full relative">
                                        <div className="w-full z-[9999]">
                                          {panel}
                                        </div>
                                        <span className="!absolute bottom-0 w-full h-[60px] !bg-white !z-[999999]" />
                                      </div>
                                    )}
                                    format={DateHelper.getFormat()}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </section>
              )}
            </div>
          </main>
        )}
      </div>
    </ConfigProvider>
  );
});
export default MainSearch;
