import { clearAuth } from "@libs/request/get-token";
import { clearLogin } from "@libs/request/localstorageService";
import UserModel from "@model/user/UserModel";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import Cookies from "js-cookie";
import { makeAutoObservable, set } from "mobx";
import moment from "moment";
import i18next from "../../../i18next";

export class CoreStore {
  token = "";
  isAuth = 0;
  loginModal = false;
  user = new UserModel();
  viewMode = "login";
  isServer = false;
  openSearch = false;
  lang = "";
  offerCardIdSlug = "";
  loginFromOfferPage = false;
  userFavoriteOffers = {
    ids: [],
    expire_at: null,
  };

  setOpenSearch = (status) => {
    const targetElement = document.querySelector("body");
    if (status) {
      disableBodyScroll(targetElement);
    } else {
      enableBodyScroll(targetElement);
    }
    this.openSearch = status;
  };

  constructor(isServer, initialData, cookie) {
    makeAutoObservable(this);
    isServer = typeof window === "undefined";
    let l = Cookies.get("lang");
    if (l === undefined) {
      Cookies.set("lang", "de");
      this.lang = "de";
    } else {
      this.lang = l;
    }

    i18next.changeLanguage(this.lang);
    moment.locale(this.lang);

    initialData = undefined;
    this.token = Cookies.get("auth_token");
    this.isAuth = Cookies.get("isAuth") ? Cookies.get("isAuth") : 0;
    if (isServer && cookie != undefined && cookie.user != undefined) {
      if (cookie.user) {
        this.userDataSet(JSON.parse(cookie.user));
      }
    }
    if (isServer) {
    } else {
      this.setDatafromLocal();
    }
  }

  setDatafromLocal = async () => {
    try {
      if (!this.isServer) {
        const user = await localStorage.getItem("user");
        await set(this.user, JSON.parse(user));
      }

      let l = Cookies.get("lang");
      if (l === undefined) {
        Cookies.set("lang", "de");
        this.lang = "de";
      } else {
        this.lang = l;
      }
      i18next.changeLanguage(this.lang);
      moment.locale(this.lang);
    } catch (e) {}
  };
  setToken = (token) => {
    this.token = token;
    this.isAuth = 1;
  };
  setLang = (lan) => {
    Cookies.set("lang", lan);
    this.lang = lan;
    location.reload();
  };
  changeViewMod = (mode) => {
    this.viewMode = mode;
  };

  logout = async () => {
    clearAuth();
    await clearLogin();
    this.isAuth = 0;
    this.token = "";
    this.user = new UserModel();
    window.localStorage.removeItem("favoriteOffers");
    window.location = "/";
  };

  userDataSet = async (data) => {
    if (data.name != undefined) {
      if (data.name.length == 0) {
        data.name = "Moverii User";
      }
    }
    set(this.user, data);
  };

  setPhoto = async (data) => {
    this.user.photo = data;
    await localStorage.setItem("user", JSON.stringify(this.user));
  };

  loginModalAction(status) {
    this.loginModal = status;
  }

  updateFavoriteOffers(expire, offerIds) {
    this.userFavoriteOffers({
      ids: offerIds,
      expire_at: expire,
    });
  }
}

let store = null;

export default function initializeStore(isServer, initialData, cookie) {
  if (isServer) {
    return new CoreStore(isServer, initialData, cookie);
  }
  if (store === null) {
    store = new CoreStore(isServer, initialData, cookie);
  }
  return store;
}
