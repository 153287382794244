import StateView from "@components/StateView/StateView";
import THelper from "@helper/THelper";
import { setToken, setUserCookie } from "@libs/request/get-token";
import { setUser } from "@libs/request/localstorageService";
import { message } from "antd";
import { makeAutoObservable } from "mobx";
import { AuthService } from "../../../queries/auth/auth.service";
import { UserService } from "../../../queries/user/user.service";

export default class AuthController {
  viewMod = "email";
  viewTab = "email";
  loginStateView = StateView.State.content;
  phone = "";
  otp = "";
  loadingOtp = false;
  canReOtp = true;
  loadingRegister = false;
  resendLoading = false;
  loadingReset = false;
  errors = {};
  loginFailed = false;
  passwordResetSuccess = false;
  afterlogin = () => {};
  router = {};
  showEmailRegisterSuccessModal = false;
  isFromOfferPage = false;
  offerCardId = "";

  constructor() {
    makeAutoObservable(this);
  }

  setRouter(router) {
    this.router = router;
  }

  setAfterLogin = (func) => {
    this.loadingOtp = false;
    this.afterlogin = func;
  };
  closeModal = () => {
    this.viewTab = "email";
    this.viewMod = "email";
    this.phone = "";
    this.otp = "";
    this.loadingRegister = false;
    this.loadingOtp = false;
    this.resendLoading = false;
    this.loadingReset = false;
  };

  changeTab = (status) => {
    this.viewTab = status;
    this.viewMod = status;
  };
  backToNumber = () => {
    this.viewMod = "phone";
  };
  resendOtp = () => {
    this.resendLoading = true;
    const data = {};
    data.mobile = this.phone;
    AuthService.resnedOtp(data)
      .then((response) => {
        this.resendLoading = false;
        this.canReOtp = true;
      })
      .catch((e) => {
        this.resendLoading = false;
        this.canReOtp = true;
      });
  };

  enabledResend = () => {
    this.canReOtp = false;
  };
  getOtpLogin = (callback) => {
    if (this.phone.length < 10) {
      message.error(THelper.t("auth.enter_mobile_number"));
      return;
    }
    this.loadingOtp = true;
    const data = {};
    data.mobile = this.phone;
    AuthService.loginMobile(data)
      .then((response) => {
        this.loadingOtp = false;
        this.viewMod = "otp";
      })
      .catch((e) => {
        this.loadingOtp = false;
      });
  };

  loginWithOtp = (callback, callbackFail) => {
    const data = {};
    data.code = this.otp;
    AuthService.loginOtp(data)
      .then((response) => {
        this.loadingOtp = false;
        this.loginStateView = StateView.State.loading;

        this.setTokenCookies(response.items.token);
        this.getUserInfo();
        this.closeModal();

        if (this.isFromOfferPage) {
          location.href = this.offerCardId;
        } else {
          this.router.reload();
        }
      })
      .catch((e) => {
        if (
          e?.response?.data?.items?.type === "msg" &&
          e?.response?.data?.items?.msg
        ) {
          message.error(e?.response?.data?.items?.msg);
        } else {
          message.error(THelper.t("global.error_try"));
        }
        callbackFail();
        this.loadingOtp = false;
      });
  };

  emailLogin = (data) => {
    this.loadingOtp = true;
    AuthService.loginEmail(data)
      .then((response) => {
        this.loadingOtp = false;
        this.loginStateView = StateView.State.loading;

        this.setTokenCookies(response.items.token);
        this.getUserInfo();

        if (this.isFromOfferPage) {
          location.href = this.offerCardId;
        } else {
          this.router.reload();
          this.closeModal();
        }
      })
      .catch((e) => {
        this.loadingOtp = false;
        this.loginFailed = true;
      });
  };

  CodeLogin = (code, callback) => {
    this.loadingOtp = true;
    AuthService.CodeLogin(code)
      .then((response) => {
        this.loginStateView = StateView.State.loading;
        this.setTokenCookies(response.items.token);
        this.getUserInfo();
        callback();
      })
      .catch((e) => {
        callback();
        // callbackFail();
        message.error(THelper.t("global.error_try"));
      });
  };

  register = async (data) => {
    this.loadingRegister = true;
    try {
      await AuthService.register(data)
        .then((res) => {
          if (res?.items?.httpStatus == 200) {
            // Log user in with token
            if (res?.items?.msg?.token) {
              this.setTokenCookies(res?.items?.msg?.token);
              this.getUserInfo();
              this.loginStateView = StateView.State.loading;
              this.loadingRegister = false;

              if (this.isFromOfferPage) {
                this.getUserInfo();

                setTimeout(() => {
                  location.href = this.offerCardId;
                }, 800);
              } else {
                this.showEmailRegisterSuccessModal = true;
                this.router.reload();
              }
            }
          }
          this.loadingRegister = false;
        })
        .catch((error) => {
          error.response?.data?.errors?.email &&
            message.error(error.response?.data?.errors?.email[0]);
          this.showEmailRegisterSuccessModal = false;
          this.loadingRegister = false;
        });
    } catch (e) {
      if (e.response.status === 422) {
        if (e.response.data.errors !== undefined) {
          this.errors = e.response.data.errors;
          console.log(this.errors);
        } else {
          message.error(THelper.t("global.error_try"));
        }
      } else {
        message.error(THelper.t("global.error_try"));
      }
      this.showEmailRegisterSuccessModal = false;
      this.loadingRegister = false;
    }
  };

  mobileReg = async (data) => {
    this.loadingOtp = true;

    // Validate phone number
    if (this.phone.length < 10) {
      message.error(THelper.t("auth.enter_mobile_number"));
      this.loadingOtp = false;
      return;
    }

    data.mobile = this.phone;

    AuthService.loginMobile(data)
      .then((response) => {
        response?.items?.msg && message.success(response?.items?.msg);
        this.loadingOtp = false;
        this.viewMod = "otp";
      })
      .catch((e) => {
        e.response?.data?.items?.msg &&
          message.error(e.response?.data?.items?.msg);
        this.loadingOtp = false;
      });
  };

  resetPassword = async (data) => {
    this.loadingReset = true;
    try {
      data = await AuthService.reset({ email: data.forget });
      this.loadingReset = false;
      message.success(THelper.t("global.send_link_reset"));
      // this.closeModal();
      this.passwordResetSuccess = true;
      const closeTimer = setTimeout(() => {
        this.closeModal();
      }, 5000);
      return () => clearTimeout(closeTimer);
    } catch (e) {
      if (e.response.status === 422) {
        if (e.response.data.errors !== undefined) {
          this.errors = e.response.data.errors;
          console.log(this.errors);
        } else {
          message.error(THelper.t("global.error_try"));
        }
      } else {
        message.error(THelper.t("global.error_try"));
      }
      this.loadingReset = false;
    }
  };

  setTokenCookies = (token) => {
    setToken(token);
  };

  setUserStorage = async (data) => {
    await setUser(data);
    await setUserCookie(data);
  };

  getUserInfo = async () => {
    UserService.info()
      .then(async (data) => {
        await this.setUserStorage(data.items);
        this.afterlogin(data.items);
        this.loginStateView = StateView.State.content;
        this.loadingOtp = false;
        this.closeModal();
      })
      .catch((e) => {
        this.getUserInfo();
        this.loadingOtp = false;
        this.loginStateView = StateView.State.content;
      });
  };

  loginSocial = (data, callback, callbackFail) => {
    AuthService.loginSocial(data)
      .then((response) => {
        this.setTokenCookies(response.msg.token);
        callback(response.data);
        this.getUserInfo();

        if (this.isFromOfferPage) {
          location.href = this.offerCardId;
        } else {
          this.router.reload();
          this.closeModal();
        }
      })
      .catch((e) => {
        callbackFail();
        message.error(THelper.t("global.error_try"));
      });
  };

  resetNewPassword = async (data, callback) => {
    this.loadingReset = true;

    AuthService.resetNewPass(data)
      .then((data) => {
        callback();
        message.success(THelper.t("change_password_successfully"));
      })
      .catch((e) => {
        this.loadingReset = false;
        message.error(THelper.t("global.error_try"));
      });
  };
}
