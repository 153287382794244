import { Image } from "antd";
import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import style from "../../styles/main.module.css";

const MultipleSlidesExample = ({ data, shadowColor, arrowClass, isHome }) => {
  const { t, i18n } = useTranslation("common");
  const [showLeft, setShowLeft] = useState(false);
  const [showRight, setShowRight] = useState(true);

  const properties = {
    nextArrow: <div>{`>`}</div>,
    prevArrow: <div>{`<`}</div>,
    dots: false,
    arrows: true,
    infinite: false,
    centerMode: false,
    centerPadding: 25,
    autoplay: false,
    swipeToSlide: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    swipe: true,
    dotsClass: "slick-dots slick-thumb slick-dots-52",
    customPaging: function (i) {
      return <div className="dot"></div>;
    },

    beforeChange: (oldIndex, newIndex) => {
      if (newIndex == data?.length - 5) {
        setShowRight(false);
      } else {
        setShowRight(true);
      }
      if (newIndex < 5) {
        setShowLeft(false);
      } else {
        setShowLeft(true);
      }
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
          arrows: false,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
          swipeToSlide: true,
          swipe: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 1.7,
          slidesToScroll: 1,
          swipeToSlide: true,
          swipe: true,
        },
      },
    ],
  };
  const shadowColordefault = shadowColor || "from-white";

  return (
    <section className={"container mx-auto"}>
      <h2
        className={
          "font-black text-[28px] catamaran flex md:justify-center pb-[24px] md:pb-[30px] text-left md:text-center"
        }
      >
        {t("frontpage.Popular destinations") || t("main.Beliebte Reiseziele")}
      </h2>

      <div className={" relative px-2 md:px-0"}>
        {showRight && (
          <div
            className={`absolute hidden lg:flex h-full ${
              isHome
                ? "home_popular_slider_shadow_right"
                : `${shadowColordefault} bg-gradient-to-l max-w-md`
            } right-0 top-0 z-20`}
            style={{ width: "18rem" }}
          ></div>
        )}
        {showLeft && (
          <div
            className={`absolute hidden lg:flex h-full ${
              isHome
                ? "home_popular_slider_shadow_left"
                : `${shadowColordefault} bg-gradient-to-r max-w-md`
            } left-0 top-0 z-20`}
            style={{ width: "18rem" }}
          ></div>
        )}

        <Slider {...properties}>
          {data?.map((v, i) => {
            return (
              <a
                href={"/s/moverii/" + v.distinction_alias}
                key={"ds" + i}
                className="z-10"
              >
                <div className={"px-1 sm:px-2 dis-min-h"}>
                  <div className={"dis-min-h relative"}>
                    <div className={`${style.resizeAbleSliderGradiant}`} />
                    <img
                      loading="lazy"
                      src={v.image || "/static/images/placeholder/provider.jpg"}
                      alt={
                        i18n.language === "en"
                          ? v.distinction_title_en
                          : v.distinction_title_de
                      }
                      className={`rounded-[20px] object-cover object-center w-full h-full`}
                    />
                    <div
                      className={classNames(
                        style.detail_offer,
                        "text-left pl-2"
                      )}
                      style={{ paddingLeft: "14px" }}
                    >
                      <h5
                        className={classNames(
                          style.title_discover_item,
                          "text-white font-bold !text-base"
                        )}
                      >
                        {i18n.language === "en"
                          ? v.distinction_title_en
                          : v.distinction_title_de}
                      </h5>
                    </div>
                  </div>
                </div>
              </a>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default MultipleSlidesExample;
