import {makeAutoObservable} from 'mobx'

export default class TransportationModel {
    constructor() {
        makeAutoObservable(this)
    }

    description = ''
    transportation = []

}
